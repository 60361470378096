/* UberMenu - Copyright 2011-2016, SevenSpark */

.ubermenu-skin-aqua {
  font-size: 12px;
  color: #888888;

  .ubermenu-target {
    color: #555555;
  }

  .ubermenu-target-description {
    color: #aaaaaa;
  }

  &.ubermenu-responsive-toggle {
    background: #fff;
    color: #888888;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
      background: #fff;
      color: #888888;
    }
  }

  /* border: 1px solid #71c275;*/
  background-color: #03b28f;

  /*background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #03b28f), color-stop(50%, #03b28f), color-stop(50%, #00936c));
  background: -webkit-linear-gradient(top, #03b28f, #00936c);
  background: -moz-linear-gradient(top, #03b28f, #00936c);
  background: -ms-linear-gradient(top, #03b28f, #00936c);
  background: -o-linear-gradient(top, #03b28f, #00936c);*/
  /*-webkit-box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);*/
  background-image: url("//infantformula.com.au/wp-content/themes/InDigitalMedia/images/Layer-111.jpg");

  .ubermenu-item-level-0 {
    &.ubermenu-current-menu-item > .ubermenu-target, &.ubermenu-current-menu-parent > .ubermenu-target, &.ubermenu-current-menu-ancestor > .ubermenu-target {
      color: #888888;
      background: #fff;
    }
  }

  .ubermenu-item.ubermenu-item-level-0 > .ubermenu-highlight {
    color: #4d90fe;
    background: transparent;
  }

  &.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-target {
    background-color: #03b28f;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #03b28f), color-stop(50%, #03b28f), color-stop(50%, #00936c));
    background: -webkit-linear-gradient(top, #03b28f, #00936c);
    background: -moz-linear-gradient(top, #03b28f, #00936c);
    background: -ms-linear-gradient(top, #03b28f, #00936c);
    background: -o-linear-gradient(top, #03b28f, #00936c);
    -webkit-box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);
    -moz-box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);
  }

  .ubermenu-item-level-0 > .ubermenu-target {
    font-weight: bold;
    color: #888888;
    text-transform: uppercase;

    /*border-left: 1px solid #000000;*/
    -webkit-box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.1);
    -moz-box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.1);
  }
}

body:not(.rtl) .ubermenu-skin-aqua.ubermenu-horizontal .ubermenu-item-level-0:first-child > .ubermenu-target {
  box-shadow: none;
}

.ubermenu-skin-aqua {
  .ubermenu-item-level-0 {
    &:hover > .ubermenu-target, &.ubermenu-active > .ubermenu-target {
      background: #ffffff;
      color: #888888;
    }

    > .ubermenu-submenu-type-stack > .ubermenu-item > .ubermenu-target {
      color: #888888;

      &:hover {
        color: #888888;
      }
    }
  }

  .ubermenu-submenu.ubermenu-submenu-drop {
    background: #fff;
    border: 1px solid #e0e0e0;
  }

  &.ubermenu-horizontal .ubermenu-item-level-0 > .ubermenu-submenu-drop {
    border-top: none;
  }

  .ubermenu-tab-content-panel {
    background: #fff;
  }

  .ubermenu-submenu {
    color: #888888;

    .ubermenu-target {
      color: #888888;
    }

    .ubermenu-highlight {
      color: #4d90fe;
    }

    .ubermenu-target:hover, .ubermenu-active > .ubermenu-target {
      color: #111111;
    }

    .ubermenu-target > .ubermenu-target-description {
      color: #aaaaaa;
    }

    .ubermenu-active > .ubermenu-target > .ubermenu-target-description {
      color: #666666;
    }

    .ubermenu-item-header {
      > .ubermenu-target {
        font-weight: bold;
        color: #888888;
      }

      &.ubermenu-has-submenu-stack > .ubermenu-target {
        border-bottom: 1px solid #888888;
      }

      > .ubermenu-target:hover {
        color: #222222;
      }
    }

    .ubermenu-current-menu-item > .ubermenu-target {
      color: #222222;
      background: transparent;
    }
  }
}

.ubermenu-nojs.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-tabs .ubermenu-tab:hover > .ubermenu-target {
  background: #eeeeee;
  color: #111111;
}

.ubermenu-skin-aqua {
  .ubermenu-submenu .ubermenu-tab.ubermenu-active > .ubermenu-target {
    background: #eeeeee;
    color: #111111;
  }

  .ubermenu-tabs-group, .ubermenu-tab > .ubermenu-target, .ubermenu-tab-content-panel {
    border-color: #eeeeee;
  }

  .ubermenu-submenu-drop {
    border-bottom-width: 3px;
  }

  .ubermenu-item .ubermenu-submenu-drop {
    -webkit-box-sizing: content-box;

    /* Takes into account borders */
    -moz-box-sizing: content-box;

    /* Takes into account borders */
    -o-box-sizing: content-box;

    /* Takes into account borders */
    box-sizing: content-box;

    /* Takes into account borders */
  }

  &.ubermenu-horizontal .ubermenu-submenu-drop {
    &.ubermenu-submenu-align-left_edge_bar, &.ubermenu-submenu-align-full_width {
      left: -1px;

      /* for borders */
    }

    &.ubermenu-submenu-align-right_edge_bar {
      right: -1px;

      /* for borders */
    }

    &.ubermenu-submenu-align-full_width.ubermenu-submenu-padded {
      width: calc(103%);

      /* because we use box-sizing:border-box */
    }
  }

  &.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-submenu-drop, &.ubermenu-nojs.ubermenu-vertical .ubermenu-item-level-0:hover > .ubermenu-submenu-drop {
    top: -1px;
  }

  &.ubermenu-vertical {
    .ubermenu-item-level-0.ubermenu-active > .ubermenu-submenu-drop, .ubermenu-active .ubermenu-submenu.ubermenu-submenu-type-flyout .ubermenu-submenu-type-mega {
      top: -1px;
    }
  }

  &.ubermenu-horizontal {
    .ubermenu-item-level-0.ubermenu-active {
      &:after {
        content: '';
        border-bottom: 1px solid transparent;
        width: 100%;
        width: calc(101%);
        margin-bottom: -1px;
      }

      > .ubermenu-submenu-drop {
        margin-top: 1px;
      }
    }

    &:not(.ubermenu-transition-shift) .ubermenu-item-level-0 > .ubermenu-submenu-drop {
      margin-top: 1px;
    }
  }

  &.ubermenu-vertical:before {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    height: 100%;
    border-right: 1px solid #000000;
    z-index: 100;
    width: 0;
  }
}

/* Drop Sub - Left Edge of Menu Bar */

/* Drop Sub - Right Edge of Menu Bar */

/*
 * UberMenu 3
 * http://wpmegamenu.com
 * Copyright 2011-2015 Chris Mavricos, SevenSpark
 */

.ubermenu {
  margin: 0;
  padding: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-indent: 0;
  clip: auto;
  position: static;
  background: 0 0;
  text-transform: none;
  overflow: visible;
  z-index: 10;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
  line-height: 1.3em;
  border: none;
  border-radius: 0;
  pointer-events: auto;

  .ubermenu-column, .ubermenu-divider, .ubermenu-icon, .uubermenu.min.cssbermenu-image, .ubermenu-item, .ubermenu-nav, .ubermenu-retractor, .ubermenu-row, .ubermenu-search, .ubermenu-search-input, .ubermenu-search-submit, .ubermenu-submenu, .ubermenu-submenu-footer, .ubermenu-tabs, .ubermenu-tabs-panels, .ubermenu-target {
    margin: 0;
    padding: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    text-indent: 0;
    clip: auto;
    position: static;
    background: 0 0;
    text-transform: none;
    overflow: visible;
    z-index: 10;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
    line-height: 1.3em;
    border: none;
    border-radius: 0;
    pointer-events: auto;
  }
}

.ubermenu-responsive-toggle {
  margin: 0;
  padding: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-indent: 0;
  clip: auto;
  position: static;
  background: 0 0;
  text-transform: none;
  overflow: visible;
  z-index: 10;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
  line-height: 1.3em;
  border: none;
  border-radius: 0;
  pointer-events: auto;
}

.ubermenu {
  height: auto;
  width: auto;
  max-height: none;
  min-height: 0;

  .ubermenu-column, .ubermenu-divider, .ubermenu-item, .ubermenu-nav, .ubermenu-retractor, .ubermenu-row, .ubermenu-search, .ubermenu-search-input, .ubermenu-search-submit, .ubermenu-submenu, .ubermenu-submenu-footer, .ubermenu-tabs, .ubermenu-tabs-panels, .ubermenu-target {
    height: auto;
    width: auto;
    max-height: none;
    min-height: 0;
  }

}

.ubermenu-responsive-toggle {
  height: auto;
  width: auto;
  max-height: none;
  min-height: 0;
}

.ubermenu {
  .ubermenu-image, .ubermenu-submenu-drop {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }

  display: block;
  line-height: 1.3em;
  text-align: left;
}

.ubermenu-bound, .ubermenu-bound-inner .ubermenu-nav {
  position: relative;
}

.ubermenu {
  overflow: visible !important;
  z-index: 100;
}
:focus,:active {
  outline: none;
}

.ubermenu-nav {
  overflow: visible !important;
  z-index: 100;
  text-align: inherit;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-flow: row;
  align-content: center;
  justify-content: space-around;

  > .ubermenu-item {
    /*background: $teal;*/
    width: 100%;
    max-height: none;
    min-height: 0;
    white-space: nowrap;
  }
}

.ubermenu-row, .ubermenu-submenu {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.ubermenu a.ubermenu-target, .ubermenu-trigger-click .ubermenu-has-submenu-drop > .ubermenu-target {
  cursor: pointer;
}

.ubermenu span.ubermenu-target {
  cursor: default;
}

.ubermenu-accessible {
  .ubermenu-target {
    outline: 0;
  }

  input:not(:hover):focus, &:not(:hover) .ubermenu-target:focus {
    outline: #2cc4ff solid 1px;
    box-shadow: 0 0 5px #2CC4FF !important;
    z-index: 50;
    transition: outline 0.1s linear;
  }
}

.ubermenu-responsive-toggle, .ubermenu-sticky-toggle-wrapper {
  display: none;
  text-decoration: none;
  cursor: pointer;
  max-width: 100%;
}

.ubermenu-responsive-toggle {
  &:hover {
    text-decoration: none;
  }

  .fa {
    width: 1.3em;
    text-align: center;
    margin-right: 0.6em;
  }
}

.ubermenu-responsive-toggle-icon-only .fa {
  margin-right: 0;
}

.ubermenu-responsive-toggle-align-left {
  float: left;
}

.ubermenu-responsive-toggle-align-right {
  float: right;
}

.ubermenu-responsive-toggle-content-align-left {
  text-align: left;
}

.ubermenu-responsive-toggle-content-align-center {
  text-align: center;
}

.ubermenu-responsive-toggle-content-align-right {
  text-align: right;
}

.ubermenu-bar-align-full {
  clear: both;
}

.ubermenu-bar-align-left {
  float: left;
}

.ubermenu-bar-align-right {
  float: right;
}

.ubermenu-bar-align-center {
  float: none;
  margin: 0 auto;
}

.ubermenu-bar-inner-center .ubermenu-nav {
  margin: 0 auto;
}

.ubermenu-items-align-center {
  text-align: center;
}

.ubermenu-items-align-right {
  text-align: right;
}

.ubermenu {
  .ubermenu-item {
    text-align: left;
    display: inline-block;
    vertical-align: top;
  }

  .ubermenu-item-level-0 {
    vertical-align: bottom;
    -moz-backface-visibility: visible;
    backface-visibility: visible;
  }
}

.ubermenu-items-align-top .ubermenu-item-level-0 {
  vertical-align: top;
}

.ubermenu .ubermenu-item.ubermenu-item-level-0 {
  float: none;
}

.ubermenu-items-align-left.ubermenu-horizontal .ubermenu-item.ubermenu-item-level-0 {
  float: left;
}

.ubermenu.ubermenu-horizontal .ubermenu-item {
  &.ubermenu-align-left {
    float: left;
  }

  &.ubermenu-align-right {
    float: right;
  }
}

body:not(.rtl) .ubermenu.ubermenu-horizontal .ubermenu-item-level-0:first-child > .ubermenu-target {
  border-left: none;
}

.ubermenu {
  .ubermenu-colgroup .ubermenu-column, .ubermenu-custom-content-padded, .ubermenu-nonlink, .ubermenu-retractor, .ubermenu-submenu-padded, .ubermenu-submenu-type-stack > .ubermenu-item-normal > .ubermenu-target, .ubermenu-target, .ubermenu-widget {
    padding: 15px 15px;
  }
}

.ubermenu-responsive-toggle {
  padding: 15px 15px;
}

.ubermenu .ubermenu-target-nowrap > .ubermenu-target-title {
  white-space: nowrap;
}

.ubermenu-horizontal .ubermenu-item.ubermenu-item-mini > .ubermenu-target {
  padding-left: 5px;
  padding-right: 5px;
}

.ubermenu {
  .ubermenu-item-level-0 {
    &.ubermenu-has-submenu-stack {
      position: relative;
    }

    > .ubermenu-submenu-type-stack {
      padding-top: 0;

      > .ubermenu-item {
        clear: both;
        width: 100%;
      }
    }
  }

  .ubermenu-submenu .ubermenu-submenu-type-stack {
    width: auto;
  }

  .ubermenu-autocolumn, .ubermenu-submenu-type-stack {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ubermenu-item-type-column > .ubermenu-submenu-type-stack {
    padding-top: 0;

    > .ubermenu-item-normal:first-child {
      margin-top: 10px;
    }
  }

  .ubermenu-submenu-type-stack > .ubermenu-item-normal > .ubermenu-target {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ubermenu-target {
    display: block;
    text-decoration: none;
    position: relative;
  }

  .ubermenu-target-with-image {
    overflow: hidden;
    display: flex !important;
    flex-flow: column !important;
    justify-content: center !important;
    align-content: center !important;
    text-align: center !important;
    align-items: center !important;
  }
}

.ubermenu-sub-indicators {
  .ubermenu-has-submenu-drop > .ubermenu-target {
    &:after {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -6px;
      content: "\f107";
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      color: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    padding-right: 25px;
  }

  .ubermenu-item-has-children > .ubermenu-target.ubermenu-noindicator {
    padding-right: 20px;
  }

  .ubermenu-item-mini.ubermenu-item-has-children > .ubermenu-target.ubermenu-noindicator {
    padding-right: 5px;
  }

  .ubermenu-has-submenu-drop > .ubermenu-noindicator:after {
    content: '';
    display: none;
  }

  .ubermenu-submenu-type-flyout > .ubermenu-has-submenu-drop > .ubermenu-target:after, .ubermenu-submenu-type-stack > .ubermenu-has-submenu-drop.ubermenu-has-submenu-flyout > .ubermenu-target:after {
    content: "";
  }

  .ubermenu-submenu .ubermenu-has-submenu-flyout.ubermenu-submenu-reverse > .ubermenu-target:after {
    right: auto;
    left: 10px;
    content: "";
  }
}

.ubermenu {
  .ubermenu-target-text {
    display: inline-block;
    vertical-align: middle;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;

  }

  .ubermenu-target-description {
    font-size: 80%;
    font-weight: 400;
    clear: both;
    display: block;
  }

  .ubermenu-target-with-image > .ubermenu-target-text {
    display: block;
    clear: none;
    text-align: center;
  }

  .ubermenu-item.ubermenu-disable-padding > .ubermenu-target {
    padding: 0;
  }

  &.ubermenu-sub-indicators .ubermenu-item.ubermenu-disable-padding.ubermenu-has-submenu-drop > .ubermenu-target {
    padding-right: 30px;
  }

  .ubermenu-image {
    display: block;
    max-width: 100%;
    transition: opacity 1s ease;
    opacity: 1;

    &:not(.ubermenu-image-lazyload) {
      height: auto;
    }
  }

  .ubermenu-image-lazyload {
    opacity: 0;
  }

  .ubermenu-item-layout-image_left > {
    .ubermenu-target-text {
      /*padding-left: 50px*/
    }

    .ubermenu-image {
      float: left;
    }
  }

  .ubermenu-item-layout-image_right > {
    .ubermenu-target-text {
      /*padding-right: 50px*/
    }

    .ubermenu-image {
      float: right;
    }
  }

  .ubermenu-item-layout-image_above > .ubermenu-image {
    margin-bottom: 10px;
  }

  .ubermenu-item-layout-image_below > .ubermenu-image {
    margin-top: 10px;
  }

  .ubermenu-icon {
    width: 1.3em;
    text-align: center;
    line-height: 1em;
  }

  .ubermenu-item-notext > .ubermenu-icon {
    line-height: 1.3em;
  }

  .ubermenu-item-layout-icon_left > .ubermenu-icon, .ubermenu-item-layout-icon_right > .ubermenu-icon {
    vertical-align: middle;
  }

  .ubermenu-item-layout-icon_bottom > .ubermenu-icon, .ubermenu-item-layout-icon_top > .ubermenu-icon {
    line-height: 1.3em;
  }

  .ubermenu-item-layout-icon_left > .ubermenu-target-title {
    margin-left: .6em;
    display: inline-block;
  }

  &.ubermenu-icons-inline .ubermenu-item-layout-icon_left > {
    .ubermenu-icon, .ubermenu-target-title {
      display: inline;
      white-space: normal;
    }
  }

  .ubermenu-content-align-left {
    text-align: left;

    > .ubermenu-image {
      display: inline-block;
    }
  }

  .ubermenu-content-align-center {
    text-align: center;

    > .ubermenu-image {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .ubermenu-content-align-right {
    text-align: right;

    > .ubermenu-image {
      display: inline-block;
    }
  }

  .ubermenu-column, .ubermenu-row {
    max-width: 100%;
  }

  .ubermenu-item {
    .ubermenu-submenu-drop {
      position: absolute;
      z-index: 500;
      top: -10000px;
      height: 0;
      max-height: 0;
      visibility: hidden;
      overflow: hidden;
    }

    &:not(.ubermenu-active) .ubermenu-submenu-drop {
      min-height: 0 !important;
    }

    .ubermenu-submenu-padded {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -o-box-sizing: border-box;
      box-sizing: border-box;
    }

    &.ubermenu-active > .ubermenu-submenu-drop {
      display: block;
      visibility: visible;
      opacity: 1;
      height: auto;
      max-height: none;
    }
  }
}

.ubermenu-nojs .ubermenu-item:hover > .ubermenu-submenu-drop {
  display: block;
  visibility: visible;
  opacity: 1;
  height: auto;
  max-height: none;
}

.ubermenu .ubermenu-item.ubermenu-active > .ubermenu-submenu-type-flyout, .ubermenu-nojs .ubermenu-item:hover > .ubermenu-submenu-type-flyout {
  overflow: visible;
}

.ubermenu {
  .ubermenu-item {
    &.ubermenu-in-transition > .ubermenu-submenu-type-flyout {
      overflow: hidden;
    }

    .ubermenu-submenu-drop {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
  }

  .ubermenu-item-level-0 > .ubermenu-submenu-drop {
    clip: rect(0, 5000px, 5000px, -5000px);
  }
}

.ubermenu-bound .ubermenu-submenu-type-mega {
  min-width: 50%;
}

.ubermenu {
  .ubermenu-grid-row, .ubermenu-submenu-grid {
    padding-right: 20px;
  }

  .ubermenu-grid-row .ubermenu-target, .ubermenu-submenu-grid .ubermenu-target {
    padding-right: 0;
  }

  .ubermenu-item.ubermenu-active > .ubermenu-target {
    position: relative;
  }
}

.ubermenu-nojs .ubermenu-item:hover > .ubermenu-target {
  position: relative;
}

.ubermenu .ubermenu-item.ubermenu-active .ubermenu-submenu-drop, .ubermenu-nojs .ubermenu-item:hover .ubermenu-submenu-drop {
  z-index: 20;
}

.ubermenu {
  .ubermenu-submenu-drop {
    &.ubermenu-submenu-align-left_edge_item {
      left: auto;
    }

    &.ubermenu-submenu-align-right_edge_item {
      right: auto;
    }

    &.ubermenu-submenu-align-full_width, &.ubermenu-submenu-align-left_edge_bar {
      left: 0;
    }

    &.ubermenu-submenu-align-right_edge_bar {
      right: 0;
    }
  }

  .ubermenu-submenu-rtl {
    direction: rtl;

    > * {
      direction: ltr;
    }
  }

  .ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
    width: 100%;
  }

  .ubermenu-retractor {
    display: block;
    clear: both;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .ubermenu-retractor-desktop {
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px 10px;
    z-index: 100;
  }

  .ubermenu-submenu-type-stack > .ubermenu-item {
    display: block;

    &.ubermenu-column-auto {
      width: 100%;
      display: block;
      float: none;
      min-width: 0;
    }
  }

  .ubermenu-item-header > .ubermenu-submenu-type-stack > .ubermenu-autocolumn {
    padding-top: 0;
    margin-top: 0;

    > .ubermenu-submenu-type-stack > .ubermenu-item-normal:first-child {
      padding-top: 0;
      margin-top: 0;
    }
  }

  .ubermenu-autocolumn + .ubermenu-item-view-all {
    clear: both;
  }

  .ubermenu-submenu-type-stack.ubermenu-submenu-indent {
    padding-left: 1.6em;
    padding-top: 0.4em;
  }

  .ubermenu-submenu-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 20px;
    color: inherit;
    font-size: inherit;
    list-style: none;

    a {
      text-decoration: none;
      font-weight: 700;
      font-size: inherit;
    }
  }

  .ubermenu-item {
    &.ubermenu-active.ubermenu-in-transition > .ubermenu-submenu-drop, &.ubermenu-in-transition > .ubermenu-submenu-drop {
      overflow: hidden !important;
      height: auto;
    }

    .ubermenu-submenu-drop {
      -webkit-transition-duration: .3s;
      -ms-transition-duration: .3s;
      transition-duration: .3s;
      -webkit-transition-timing-function: ease-in;
      -ms-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
    }
  }
}

.ubermenu-transition-none .ubermenu-item .ubermenu-submenu-drop {
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
  top: auto;
}

.ubermenu-nojs.ubermenu-transition-none .ubermenu-item:hover > .ubermenu-submenu-drop, .ubermenu-transition-none .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
  max-height: none;
  height: auto;
  overflow-y: visible;
}

.ubermenu-transition-slide .ubermenu-item .ubermenu-submenu-drop {
  -webkit-transition-property: max-height, visibility;
  -ms-transition-property: max-height, visibility;
  transition-property: max-height, visibility;
  max-height: 0;
  top: auto;
}

.ubermenu-nojs.ubermenu-transition-slide .ubermenu-item:hover > .ubermenu-submenu-drop, .ubermenu-transition-slide .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
  max-height: 1000px;
}

.ubermenu-transition-fade .ubermenu-item .ubermenu-submenu-drop {
  opacity: 0;
  -webkit-transition-property: opacity, visibility;
  -ms-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  top: auto;
  margin-top: 1px;
}

.ubermenu-nojs.ubermenu-transition-fade .ubermenu-item:hover > .ubermenu-submenu-drop, .ubermenu-transition-fade .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
  opacity: 1;
  top: auto;
}

.ubermenu-transition-shift .ubermenu-item .ubermenu-submenu-drop {
  margin-top: 20px;
  top: auto;
  opacity: 0;
  -webkit-transition-property: margin-top, opacity, visibility;
  -ms-transition-property: margin-top, opacity, visibility;
  transition-property: margin-top, opacity, visibility;
}

.ubermenu-nojs.ubermenu-transition-shift .ubermenu-item:hover > .ubermenu-submenu-drop, .ubermenu-transition-shift .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
  margin-top: 0;
  opacity: 1;
}

.ubermenu {
  &.ubermenu-transition-slide .ubermenu-active > .ubermenu-submenu.ubermenu-submenu-type-mega, &:not(.ubermenu-transition-slide) .ubermenu-submenu.ubermenu-submenu-type-mega {
    max-height: 600px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &.ubermenu-disable-submenu-scroll {
    .ubermenu-item > .ubermenu-submenu.ubermenu-submenu-type-mega {
      overflow: hidden;
    }

    .ubermenu-active > .ubermenu-submenu.ubermenu-submenu-type-mega {
      max-height: none;
    }
  }

  .ubermenu-submenu-type-flyout > .ubermenu-item {
    display: block;
    float: none;
    position: relative;
    min-width: 150px;
    clear: both;
  }

  &:not(.ubermenu-transition-slide) .ubermenu-submenu.ubermenu-submenu-type-flyout {
    max-height: 99999px;
  }

  .ubermenu-submenu-type-flyout > .ubermenu-item.ubermenu-active {
    z-index: 50;
  }

  .ubermenu-submenu {
    .ubermenu-submenu-type-flyout {
      margin-top: -1px;
      top: 0;
      left: 100%;
      min-width: none;
      clip: rect(-10px, 5000px, 5000px, 0);

      &.ubermenu-submenu-align-right_edge_item {
        left: auto;
        right: 100%;
        clip: rect(-10px, 5000px, 5000px, -5000px);
      }
    }

    .ubermenu-item.ubermenu-active > .ubermenu-submenu-type-flyout {
      top: 0;
    }
  }

  .ubermenu-submenu-type-flyout > .ubermenu-item {
    &.ubermenu-has-submenu-mega {
      position: static;
    }

    > .ubermenu-submenu.ubermenu-submenu-type-mega {
      left: 100%;
      top: 0;
      min-height: 100%;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      -ms-box-sizing: content-box;
      box-sizing: content-box;
      clip: rect(-10px, 5000px, 5000px, -5000px);
    }
  }

  &.ubermenu-horizontal {
    .ubermenu-item-level-1:first-child > .ubermenu-submenu-type-flyout {
      border-top: none;

      > .ubermenu-item:first-child > .ubermenu-submenu-type-flyout {
        border-top: none;
      }
    }

    .ubermenu-submenu-type-flyout .ubermenu-submenu-type-mega {
      border-top: none;
    }
  }

  .ubermenu-item .ubermenu-submenu-type-mega {
    .ubermenu-submenu-drop {
      margin-top: 0;
      clip: rect(0, 5000px, 5000px, -5000px);
    }

    .ubermenu-item.ubermenu-has-submenu-flyout {
      position: relative;
    }

    > .ubermenu-item.ubermenu-has-submenu-flyout > .ubermenu-submenu-drop {
      top: auto;
      left: auto;
      box-shadow: none;
    }
  }

  .ubermenu-submenu-type-mega .ubermenu-item.ubermenu-has-submenu-flyout.ubermenu-active {
    z-index: 100;
  }

  .ubermenu-row {
    margin-bottom: 10px;
    clear: both;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .ubermenu-clear-row {
    clear: both;
  }

  .ubermenu-submenu .ubermenu-column {
    display: block;
    float: left;
    width: auto;
  }

  .ubermenu-submenu-type-mega {
    &.ubermenu-submenu-content-align-left {
      text-align: left;
    }

    &.ubermenu-submenu-content-align-right {
      text-align: right;
    }

    &.ubermenu-submenu-content-align-center {
      text-align: center;

      > {
        .ubermenu-column, .ubermenu-row > .ubermenu-column {
          display: inline-block;
          float: none;
        }
      }
    }

    &.ubermenu-submenu-content-align-left > {
      .ubermenu-column, .ubermenu-row > .ubermenu-column {
        display: inline-block;
        float: none;
      }
    }

    &.ubermenu-submenu-content-align-right > {
      .ubermenu-column, .ubermenu-row > .ubermenu-column {
        display: inline-block;
        float: none;
      }
    }
  }

  .ubermenu-submenu .ubermenu-column-auto {
    min-width: 100px;
    width: auto;
  }

  .ubermenu-nav {
    .ubermenu-column-full {
      width: 100%;
    }

    .ubermenu-column-1-2 {
      width: 50%;
    }

    .ubermenu-column-1-3 {
      width: 33.33%;
    }

    .ubermenu-column-2-3 {
      width: 66.66%;
    }

    .ubermenu-column-1-4 {
      width: 25%;
    }

    .ubermenu-column-3-4 {
      width: 75%;
    }

    .ubermenu-column-1-5 {
      width: 20%;
    }

    .ubermenu-column-2-5 {
      width: 40%;
    }

    .ubermenu-column-3-5 {
      width: 60%;
    }

    .ubermenu-column-4-5 {
      width: 80%;
    }

    .ubermenu-column-1-6 {
      width: 16.66%;
    }

    .ubermenu-column-5-6 {
      width: 83.33%;
    }

    .ubermenu-column-1-7 {
      width: 14.28%;
    }

    .ubermenu-column-2-7 {
      width: 28.57%;
    }

    .ubermenu-column-3-7 {
      width: 42.85%;
    }

    .ubermenu-column-4-7 {
      width: 57.14%;
    }

    .ubermenu-column-5-7 {
      width: 71.42%;
    }

    .ubermenu-column-6-7 {
      width: 85.71%;
    }

    .ubermenu-column-1-8 {
      width: 12.5%;
    }

    .ubermenu-column-3-8 {
      width: 37.5%;
    }

    .ubermenu-column-5-8 {
      width: 62.5%;
    }

    .ubermenu-column-7-8 {
      width: 87.5%;
    }

    .ubermenu-column-1-9 {
      width: 11.11%;
    }

    .ubermenu-column-2-9 {
      width: 22.22%;
    }

    .ubermenu-column-4-9 {
      width: 44.44%;
    }

    .ubermenu-column-5-9 {
      width: 55.55%;
    }

    .ubermenu-column-7-9 {
      width: 77.77%;
    }

    .ubermenu-column-8-9 {
      width: 88.88%;
    }

    .ubermenu-column-1-10 {
      width: 10%;
    }

    .ubermenu-column-3-10 {
      width: 30%;
    }

    .ubermenu-column-7-10 {
      width: 70%;
    }

    .ubermenu-column-9-10 {
      width: 90%;
    }

    .ubermenu-column-1-11 {
      width: 9.09%;
    }

    .ubermenu-column-2-11 {
      width: 18.18%;
    }

    .ubermenu-column-3-11 {
      width: 27.27%;
    }

    .ubermenu-column-4-11 {
      width: 36.36%;
    }

    .ubermenu-column-5-11 {
      width: 45.45%;
    }

    .ubermenu-column-6-11 {
      width: 54.54%;
    }

    .ubermenu-column-7-11 {
      width: 63.63%;
    }

    .ubermenu-column-8-11 {
      width: 72.72%;
    }

    .ubermenu-column-9-11 {
      width: 81.81%;
    }

    .ubermenu-column-10-11 {
      width: 90.9%;
    }

    .ubermenu-column-1-12 {
      width: 8.33%;
    }

    .ubermenu-column-5-12 {
      width: 41.66%;
    }

    .ubermenu-column-7-12 {
      width: 58.33%;
    }

    .ubermenu-column-11-12 {
      width: 91.66%;
    }
  }

  .ubermenu-autoclear {
    > {
      .ubermenu-column-1-10:nth-child(10n+1), .ubermenu-column-1-11:nth-child(11n+1), .ubermenu-column-1-12:nth-child(12n+1), .ubermenu-column-1-2:nth-child(2n+1), .ubermenu-column-1-3:nth-child(3n+1), .ubermenu-column-1-4:nth-child(4n+1), .ubermenu-column-1-5:nth-child(5n+1), .ubermenu-column-1-6:nth-child(6n+1), .ubermenu-column-1-7:nth-child(7n+1), .ubermenu-column-1-8:nth-child(8n+1), .ubermenu-column-1-9:nth-child(9n+1), .ubermenu-column-full {
        clear: both;
      }
    }

    &.ubermenu-submenu-retractor-top {
      > {
        .ubermenu-column {
          clear: none;
        }

        .ubermenu-column-1-10:nth-child(10n+2), .ubermenu-column-1-11:nth-child(11n+2), .ubermenu-column-1-12:nth-child(12n+2), .ubermenu-column-1-2:nth-child(2n+2), .ubermenu-column-1-3:nth-child(3n+2), .ubermenu-column-1-4:nth-child(4n+2), .ubermenu-column-1-5:nth-child(5n+2), .ubermenu-column-1-6:nth-child(6n+2), .ubermenu-column-1-7:nth-child(7n+2), .ubermenu-column-1-8:nth-child(8n+2), .ubermenu-column-1-9:nth-child(9n+2), .ubermenu-column-full {
          clear: both;
        }
      }

      &.ubermenu-submenu-retractor-top-2 > .ubermenu-column {
        clear: none;
      }
    }

    &.ubermenu-submenu-retractor-top-2 > {
      .ubermenu-column-1-10:nth-child(10n+3), .ubermenu-column-1-11:nth-child(11n+3), .ubermenu-column-1-12:nth-child(12n+3), .ubermenu-column-1-2:nth-child(2n+3), .ubermenu-column-1-3:nth-child(3n+3), .ubermenu-column-1-4:nth-child(4n+3), .ubermenu-column-1-5:nth-child(5n+3), .ubermenu-column-1-6:nth-child(6n+3), .ubermenu-column-1-7:nth-child(7n+3), .ubermenu-column-1-8:nth-child(8n+3), .ubermenu-column-1-9:nth-child(9n+3), .ubermenu-column-full {
        clear: both;
      }
    }
  }

  .ubermenu-tabs {
    position: relative;
    width: 100%;
  }

  .ubermenu-tabs-group {
    border-style: solid;
  }

  .ubermenu-tab {
    .ubermenu-tab-content-panel {
      position: absolute;
      display: none;
      border-width: 0;
      border-style: solid;
    }

    > .ubermenu-target {
      border-width: 0;
      border-style: solid;
    }

    &.ubermenu-active > .ubermenu-tab-content-panel {
      display: block;
    }
  }
}

.ubermenu-nojs {
  .ubermenu-tab:hover > .ubermenu-tab-content-panel {
    display: block;
  }

  .ubermenu-tabs-show-default {
    > .ubermenu-tabs-group > .ubermenu-tab:first-child > .ubermenu-tab-content-panel {
      display: block;
    }

    &:hover > .ubermenu-tabs-group > .ubermenu-tab:first-child {
      > .ubermenu-tab-content-panel {
        display: none;
      }

      &:hover > .ubermenu-tab-content-panel {
        display: block;
      }
    }
  }
}

.ubermenu .ubermenu-tab-layout-left > .ubermenu-tabs-group {
  float: left;
  border-width: 0 1px 0 0;
  border-color: #eeeeee;
  > .ubermenu-tab > .ubermenu-tab-content-panel {
    right: 0;
    top: 0;
  }
}

.ubermenu-sub-indicators .ubermenu-tabs.ubermenu-tab-layout-left > .ubermenu-tabs-group > .ubermenu-has-submenu-drop > .ubermenu-target:after {
  content: "";
}

.ubermenu .ubermenu-tab-layout-right > .ubermenu-tabs-group {
  float: right;
  border-width: 0 0 0 1px;

  > {
    .ubermenu-tab > .ubermenu-tab-content-panel {
      left: 0;
      top: 0;
    }

    .ubermenu-has-submenu-drop > .ubermenu-target {
      padding-left: 25px;
      padding-right: 20px;
    }
  }
}

.ubermenu-sub-indicators .ubermenu-tab-layout-right > .ubermenu-tabs-group > .ubermenu-has-submenu-drop > .ubermenu-target:after {
  content: "\f104";
  right: auto;
  left: 10px;
}

.ubermenu {
  .ubermenu-tab-layout-top > .ubermenu-tabs-group {
    border-width: 0;

    > .ubermenu-tab > .ubermenu-tab-content-panel {
      left: 0;
      top: auto;
      border-width: 1px 0 0;
    }
  }

  .ubermenu-tab-layout-bottom > .ubermenu-tabs-group {
    border-width: 1px 0 0;
    position: absolute;
    bottom: 0;

    > .ubermenu-tab > .ubermenu-tab-content-panel {
      left: 0;
      bottom: 100%;
    }
  }
}

.ubermenu-sub-indicators .ubermenu-tabs.ubermenu-tab-layout-bottom > .ubermenu-tabs-group > .ubermenu-has-submenu-drop > .ubermenu-target:after {
  content: "";
}

.ubermenu h3.ubermenu-widgettitle {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 1.3em;
}

.widget.ubermenu_navigation_widget-class {
  overflow: visible;
}

.ubermenu {
  .ubermenu-divider {
    clear: both;
    display: block;
    padding: 20px 0;
  }

  .ubermenu-submenu-type-stack > .ubermenu-divider {
    padding: 10px 0;
  }

  .ubermenu-divider hr {
    padding: 0;
    margin: 0;
    display: block;
    height: 1px;
    background: 0 0;
    border: 0;
    border-top: 1px solid #ddd;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .ubermenu-search {
    .ubermenu-searchform {
      position: relative;
      background: #f9f9f9;
      width: 150px;
      margin: 7px;
    }

    input.ubermenu-search-input {
      width: 100%;
      padding: 8px 40px 8px 15px;
      color: #333;
      background: 0 0;
      border: none;
    }

    .ubermenu-search-submit {
      position: absolute;
      width: 40px;
      height: 100%;
      top: 0;
      right: 0;
      border: none;
      background: 0 0;
      cursor: pointer;
      color: #666;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .ubermenu-searchbar-drop .ubermenu-search-submit {
    position: absolute;
    width: 40px;
    height: 100%;
    top: 0;
    right: 0;
    border: none;
    background: 0 0;
    cursor: pointer;
    color: #666;
    font-family: FontAwesome !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ubermenu-submenu {
    .ubermenu-search {
      .ubermenu-searchform {
        height: 50px;
        margin: 0;
        background: #f9f9f9;
        width: 100%;
      }

      input.ubermenu-search-input {
        width: 100%;
        padding: 14px 50px 14px 25px;
        height: 100%;
        color: #333;
        background: 0 0;
      }

      .ubermenu-search-submit {
        position: absolute;
        width: 50px;
        height: 100%;
        top: 0;
        right: 0;
        border: none;
        background: 0 0;
        cursor: pointer;
        color: #666;
        font-family: FontAwesome !important;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .ubermenu-searchbar-drop .ubermenu-search-submit {
      position: absolute;
      width: 50px;
      height: 100%;
      top: 0;
      right: 0;
      border: none;
      background: 0 0;
      cursor: pointer;
      color: #666;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .ubermenu-postlist-item {
    margin-bottom: 10px;
  }

  .ubermenu-postlist .ubermenu-image {
    float: left;
  }

  .ubermenu-postlist-title > a {
    color: inherit;
    text-decoration: none;
  }

  .ubermenu-postlist-content {
    font-size: 11px;
    opacity: 0.7;
  }

  .ubermenu-map-canvas img {
    max-width: none;
  }
}

.ubermenu-screen-reader, .ubermenu-target-divider {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  padding: 0;
  margin: 0;
  border: 0;
}

.uber-clear:after {
  content: "";
  display: table;
  clear: both;
}

.ubermenu {
  .ubermenu-column:after, .ubermenu-content-block:after, .ubermenu-image:after, .ubermenu-postlist-item:after, .ubermenu-submenu:after, .ubermenu-tabs:after, &:after {
    content: "";
    display: table;
    clear: both;
  }

  background: 0 0;
}

.ubermenu-submenu-drop {
  background: #fff;
}

.ubermenu-test-dimensions {
  visibility: hidden !important;
  display: block !important;
}

.ubermenu .ubermenu-force > .ubermenu-submenu {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  margin: 0 !important;
  top: auto !important;
  max-height: 600px;
  height: auto;
  overflow: auto !important;
}

.ubermenu-notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;

  > .ubermenu-submenu-drop {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
  }
}

.ubermenu-admin-notice {
  background: #eee;
  color: #333;
  padding: 15px;
  border-left: 30px solid #4D90FE;
  margin: 20px;
  position: relative;
  font-size: 12px;
  clear: both;

  a {
    color: #00A3FF;
    text-decoration: underline;
    font-size: inherit;

    &:visited {
      color: #00A3FF;
      text-decoration: underline;
      font-size: inherit;
    }

    &:hover {
      color: #0D75AF;
    }
  }

  strong {
    color: tomato;
  }

  .ubermenu-admin-notice-icon {
    position: absolute;
    left: -18px;
    top: 50%;
    margin-top: -5px;
    color: #fff;
    font-size: 13px;
  }

  &:after {
    display: none;
    position: absolute;
    content: 'UberMenu Notice: Only displayed to admin users';
    left: -30px;
    bottom: 100%;
    background: #666;
    color: #eee;
    padding: 7px 10px;
    font-size: 11px;
  }

  &:hover:after {
    display: block;
  }
}

#wp-admin-bar-ubermenu .fa {
  font-family: FontAwesome;
  margin-right: 4px;
  color: #aaa;
}

#wp-admin-bar-ubermenu_loading .fa {
  font-family: FontAwesome;
  margin-right: 4px;
  color: #aaa;
  color: #ff0;
}

#wpadminbar #wp-admin-bar-ubermenu_loading #wp-admin-bar-ubermenu_loading_msg .ab-item {
  width: 300px;
  white-space: normal;
  height: auto;
  padding: 10px 20px;
  line-height: 1.5em;
}

.ubermenu-responsive-toggle.ubermenu-loc-ubermenu-direct-inject, .ubermenu.ubermenu-loc-ubermenu-direct-inject {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.admin-bar {
  .ubermenu-responsive-toggle.ubermenu-loc-ubermenu-direct-inject, .ubermenu.ubermenu-loc-ubermenu-direct-inject {
    top: 32px;
  }
}
span.ubermenu-target-title.ubermenu-target-text {
  //font-size: 1.25em;
  font-size: 1.40em;
  text-transform: capitalize;
  font-weight: 100;
}
.ubermenu-submenu-type-auto span.ubermenu-target-title.ubermenu-target-text {
  font-size: 1em;
}
.ubermenu-item-layout-image_left span.ubermenu-target-title.ubermenu-target-text {
  display: block;
  width: 80%;
  margin: 0 auto;
  white-space: normal;
}

@media screen and (min-width: 959px) {
 /* ul#ubermenu-nav-main-15 > :nth-child(1),
  ul#ubermenu-nav-main-15 > :nth-child(2),
  ul#ubermenu-nav-main-15 > :nth-child(3){
    min-width: 15%;
  }
  ul#ubermenu-nav-main-15 > :nth-child(4){
    min-width: 10%;
  }
  ul#ubermenu-nav-main-15 > :nth-child(5){
    min-width: 10%;
  }
  ul#ubermenu-nav-main-15 > :nth-child(6),
  ul#ubermenu-nav-main-15 > :nth-child(7){
    max-width: 10%;
  }*/
  ul#ubermenu-nav-main-15 {
    max-width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 959px) {
  .ubermenu.ubermenu-loc-ubermenu-direct-inject {
    margin-top: 47px;
  }
  .ubermenu-nav {
    display: block;
  }
  .ubermenu-tab-content-panel {
    background: #fff !important;
    border: none !important;
  }
  .ubermenu .ubermenu-item-layout-image_left > .ubermenu-target-text {
    /* padding-left: 50px; */
    white-space: pre-wrap;

  }
  .ubermenu-nav > .ubermenu-item {
    white-space: normal;
  }
}

@media screen and (max-width: 782px) {
  .admin-bar {
    .ubermenu-responsive-toggle.ubermenu-loc-ubermenu-direct-inject, .ubermenu.ubermenu-loc-ubermenu-direct-inject {
      top: 46px;
    }
  }

  .ubermenu-responsive-toggle.ubermenu-loc-ubermenu-direct-inject, .ubermenu.ubermenu-loc-ubermenu-direct-inject {
    position: absolute;
  }
}

.ubermenu-vertical {
  .ubermenu-item-level-0 {
    display: block;
    clear: both;
  }

  &.ubermenu-items-align-right .ubermenu-item-level-0 {
    text-align: right;
  }

  &.ubermenu-items-align-center .ubermenu-item-level-0 {
    text-align: center;
  }

  .ubermenu-item-level-0 > .ubermenu-submenu-drop {
    clip: rect(-5000px, 5000px, 5000px, 0);
  }
}

.ubermenu.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-target {
  box-shadow: none;
  border-left: none;
}

.ubermenu-vertical {
  &.ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-item-has-children > .ubermenu-target:after {
    content: "";
  }

  .ubermenu-item > .ubermenu-submenu-drop {
    left: 100%;
    margin-left: 0;
    top: 0;
  }
}

.ubermenu-nojs.ubermenu-vertical .ubermenu-item-level-0:hover > .ubermenu-submenu-drop {
  top: 0;
  min-height: 100%;
}

.ubermenu-vertical {
  .ubermenu-active .ubermenu-submenu.ubermenu-submenu-type-flyout .ubermenu-submenu-type-mega, .ubermenu-item-level-0.ubermenu-active > .ubermenu-submenu-drop {
    top: 0;
    min-height: 100%;
  }
}

.ubermenu-nojs.ubermenu-vertical .ubermenu-item-level-0:hover > .ubermenu-submenu-drop.ubermenu-submenu-align-vertical_parent_item {
  min-height: 0;
}

.ubermenu-vertical {
  .ubermenu-item-level-0.ubermenu-active > .ubermenu-submenu-drop.ubermenu-submenu-align-vertical_parent_item {
    min-height: 0;
  }

  .ubermenu-item.ubermenu-flyout-full-height {
    position: static;

    > .ubermenu-submenu-drop {
      min-height: 100% !important;
      max-height: none;
      height: auto;
    }
  }

  .ubermenu-submenu .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
    top: 0;
  }

  &.ubermenu-transition-slide .ubermenu-item.ubermenu-active.ubermenu-in-transition > .ubermenu-submenu-drop {
    min-height: 0;
  }

  .ubermenu-item > .ubermenu-submenu-type-mega {
    width: 600px;
    margin: 0;
  }

  .ubermenu-tab-layout-left > .ubermenu-tabs-group > .ubermenu-tab > .ubermenu-tab-content-panel, .ubermenu-tab-layout-right > .ubermenu-tabs-group > .ubermenu-tab > .ubermenu-tab-content-panel {
    min-height: 100%;
  }

  .ubermenu-item {
    &.ubermenu-relative {
      position: relative;

      &.ubermenu-active {
        z-index: 20;
      }
    }

    &.ubermenu-item-level-0.ubermenu-relative.ubermenu-active > .ubermenu-submenu-drop.ubermenu-submenu-align-vertical_parent_item {
      top: 0;
    }

    &.ubermenu-item-mini {
      float: left;
      clear: none;
    }
  }
}

.ubermenu.ubermenu-vertical .ubermenu-item.ubermenu-item-mini > .ubermenu-target {
  background: 0 0;
  box-shadow: none;
}

.rtl {
  .ubermenu {
    text-align: right;

    .ubermenu-item {
      text-align: right;
    }
  }

  .ubermenu-items-align-left.ubermenu-horizontal {
    text-align: left;
  }

  .ubermenu-items-align-center.ubermenu-horizontal {
    text-align: center;
  }

  .ubermenu-items-align-left.ubermenu-horizontal .ubermenu-item.ubermenu-item-level-0 {
    float: none;
  }

  .ubermenu {
    &.ubermenu-horizontal .ubermenu-item-level-0:last-child > .ubermenu-target {
      border-left: none;
      box-shadow: none;
    }

    .ubermenu-submenu-type-mega > .ubermenu-column:not(.ubermenu-tabs-group), .ubermenu-submenu-type-tab-content-panel > .ubermenu-column:not(.ubermenu-tabs-group) {
      float: right;
    }

    .ubermenu-item-layout-icon_left > .ubermenu-target-title {
      margin-left: 0;
      margin-right: 0.6em;
    }
  }

  .ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-has-submenu-drop > .ubermenu-target {
    padding-right: 20px;
    padding-left: 25px;

    &:after {
      right: auto;
      left: 10px;
    }
  }

  .ubermenu.ubermenu-sub-indicators .ubermenu-item.ubermenu-disable-padding.ubermenu-has-submenu-drop > .ubermenu-target {
    padding-left: 30px;
    padding-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .ubermenu-responsive-default {
    &.ubermenu {
      display: block !important;
    }

    &.ubermenu-responsive {
      .ubermenu-item.ubermenu-hide-desktop {
        display: none !important;
      }

      &.ubermenu-retractors-responsive .ubermenu-retractor-mobile {
        display: none;
      }
    }

    &.ubermenu-force-current-submenu .ubermenu-item-level-0 {
      &.ubermenu-current-menu-ancestor > .ubermenu-submenu-drop, &.ubermenu-current-menu-item > .ubermenu-submenu-drop {
        display: block !important;
        opacity: 1 !important;
        visibility: visible !important;
        margin: 0 !important;
        top: auto !important;
        height: auto;
        z-index: 19;
      }
    }

    &.ubermenu-invert {
      &.ubermenu-horizontal {
        .ubermenu-item-level-0 > .ubermenu-submenu-drop {
          top: auto;
          bottom: 100%;
        }

        &.ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-has-submenu-drop > .ubermenu-target:after {
          content: "";
        }

        .ubermenu-submenu .ubermenu-item.ubermenu-active > .ubermenu-submenu-type-flyout {
          top: auto;
          bottom: 0;
        }

        .ubermenu-item-level-0 > .ubermenu-submenu-drop {
          clip: rect(-5000px, 5000px, auto, -5000px);
        }
      }

      &.ubermenu-vertical {
        .ubermenu-item-level-0 > .ubermenu-submenu-drop {
          right: 100%;
          left: auto;
        }

        &.ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-item-has-children > a.ubermenu-target:after {
          right: auto;
          left: 10px;
          margin-top: -7px;
          content: "";
        }
      }
    }

    &.ubermenu-vertical.ubermenu-invert .ubermenu-item > .ubermenu-submenu-drop {
      clip: rect(-5000px, 5000px, 5000px, -5000px);
    }
  }
}

@media screen and (max-width: 959px) {
  .ubermenu-responsive-toggle, .ubermenu-sticky-toggle-wrapper {
    display: block;
  }

  .ubermenu-no-transitions.ubermenu-responsive.ubermenu-responsive-collapse {
    display: none;
    visibility: visible;
    height: auto;
    max-height: none;
    overflow: visible !important;
  }

  .ubermenu-sticky-wrapper {
    min-height: 0 !important;
  }

  .ubermenu-sticky-toggle-wrapper {
    display: block;
  }

  .ubermenu-responsive-default {
    .ubermenu-responsive-toggle, .ubermenu-sticky-toggle-wrapper {
      display: block;
    }

    &.ubermenu-responsive {
      width: 100%;
      max-height: 500px;
      visibility: visible;
      overflow: visible;
      -webkit-transition: max-height 1s ease-in;
      transition: max-height 0.3s ease-in;

      &.ubermenu-items-align-center {
        text-align: left;
      }

      &.ubermenu {
        margin: 0;

        .ubermenu-nav {
          display: block;
        }
      }
    }

    &.ubermenu-repsonsive.ubermenu-no-transitions {
      display: block;
      max-height: none;
    }

    &.ubermenu-responsive {
      &.ubermenu-responsive-nocollapse {
        display: block;
        max-height: none;
      }

      &.ubermenu-responsive-collapse {
        max-height: 0;
        overflow: hidden !important;
        visibility: hidden;
      }

      &.ubermenu-in-transition {
        overflow: hidden !important;
        visibility: visible;

        .ubermenu-nav {
          overflow: hidden !important;
          visibility: visible;
        }
      }

      &.ubermenu-responsive-collapse {
        &:not(.ubermenu-in-transition) {
          border-top-width: 0;
          border-bottom-width: 0;
        }

        .ubermenu-item .ubermenu-submenu {
          display: none;
        }
      }

      .ubermenu-item-level-0 {
        width: 50%;
      }

      &.ubermenu-responsive-single-column .ubermenu-item-level-0 {
        float: none;
        clear: both;
        width: 100%;
      }

      .ubermenu-item {
        &.ubermenu-item-level-0 > .ubermenu-target {
          border: none;
          box-shadow: none;
        }

        &.ubermenu-has-submenu-flyout {
          position: static;
        }
      }

      &.ubermenu-sub-indicators .ubermenu-submenu-type-flyout .ubermenu-has-submenu-drop > .ubermenu-target:after {
        content: "";
      }

      .ubermenu-nav .ubermenu-item .ubermenu-submenu.ubermenu-submenu-drop {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        top: auto;
        left: 0 !important;
      }

      &.ubermenu-has-border .ubermenu-nav .ubermenu-item .ubermenu-submenu.ubermenu-submenu-drop {
        left: -1px !important;
      }

      .ubermenu-submenu.ubermenu-submenu-type-mega > .ubermenu-item.ubermenu-column {
        min-height: 0;
        border-left: none;
        float: left;
        display: block;
      }

      .ubermenu-item.ubermenu-active > .ubermenu-submenu.ubermenu-submenu-type-mega {
        max-height: none;
        height: auto;
        overflow: visible;
      }

      &.ubermenu-transition-slide .ubermenu-item.ubermenu-in-transition > .ubermenu-submenu-drop {
        max-height: 1000px;
      }
    }

    &.ubermenu {
      .ubermenu-submenu-type-flyout .ubermenu-submenu-type-mega {
        min-height: 0;
      }

      &.ubermenu-responsive {
        .ubermenu-column, .ubermenu-column-auto {
          /*min-width: 50%*/
        }

        .ubermenu-autoclear > .ubermenu-column {
          /*clear: none*/
        }

        .ubermenu-column:nth-of-type(2n+1) {
          /*clear: both*/
        }

        .ubermenu-submenu-retractor-top:not(.ubermenu-submenu-retractor-top-2) .ubermenu-column {
          &:nth-of-type(2n+1) {
            /*clear: none*/
          }

          &:nth-of-type(2n+2) {
            /*clear: both*/
          }
        }

        .ubermenu-tab, .ubermenu-tab-content-panel, .ubermenu-tabs, .ubermenu-tabs-group {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          left: 0;
        }

        .ubermenu-tab-content-panel {
          min-height: 0 !important;
        }

        .ubermenu-tabs {
          min-height: 0 !important;
          z-index: 15;
        }

        .ubermenu-tab-content-panel {
          z-index: 20;
        }
      }
    }

    &.ubermenu-responsive {
      .ubermenu-tab {
        position: relative;

        &.ubermenu-active {
          position: relative;
          z-index: 20;
        }

        > .ubermenu-target {
          border-width: 0 0 1px;
        }
      }

      &.ubermenu-sub-indicators .ubermenu-tabs > .ubermenu-tabs-group > .ubermenu-tab.ubermenu-has-submenu-drop > .ubermenu-target:after {
        content: "";
      }

      .ubermenu-tabs > .ubermenu-tabs-group > .ubermenu-tab > .ubermenu-tab-content-panel {
        top: auto;
        border-width: 1px;
      }
    }

    &.ubermenu-reponsive .ubermenu-item-level-0 > .ubermenu-submenu-type-stack {
      position: relative;
    }

    &.ubermenu-responsive {
      .ubermenu-submenu-type-stack {
        .ubermenu-column, .ubermenu-column-auto {
          width: 100%;
          max-width: 100%;
        }
      }

      .ubermenu-item-mini {
        min-width: 0;
        width: auto;
        float: left;
        clear: none !important;
      }

      .ubermenu-item {
        &.ubermenu-item-mini > a.ubermenu-target {
          padding-left: 20px;
          padding-right: 20px;
        }

        &.ubermenu-hide-mobile {
          display: none !important;
        }
      }

      &.ubermenu-hide-bkgs .ubermenu-submenu.ubermenu-submenu-bkg-img {
        background-image: none;
      }
    }

    &.ubermenu.ubermenu-responsive .ubermenu-item-level-0.ubermenu-item-mini {
      min-width: 0;
      width: auto;
    }

    &.ubermenu-vertical {
      .ubermenu-item.ubermenu-item-level-0 {
        width: 100%;
      }

      &.ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-item-has-children > .ubermenu-target:after {
        content: "";
      }

      .ubermenu-item.ubermenu-item-level-0.ubermenu-relative.ubermenu-active > .ubermenu-submenu-drop.ubermenu-submenu-align-vertical_parent_item {
        top: auto;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive {
    .ubermenu-item-level-0 {
      width: 100%;
    }

    .ubermenu-column, .ubermenu-column-auto {
      min-width: 50%;
    }
  }
}

li.ubermenu-item.ubermenu-item-has-children.ubermenu-column.ubermenu-column-auto.ubermenu-has-submenu-drop.parent-menu-item,
li.ubermenu-item.ubermenu-column.ubermenu-column-auto.parent-menu-item{
  border-top:1px solid #cccccc ;
  &.ubermenu-current-menu-item{
    border-color: #42b07a;
  }
}