@mixin breakpoint($point){
  @if ($point == lg){
    @media (min-width: 1200px){ @content }
  }
  @else if ($point == md){
    @media (min-width: 992px) and (max-width: 1199px){ @content }
  }

  @else if ($point == sm){
    @media (min-width: 768px) and (max-width: 991px){ @content }
  }

  @else if ($point == xs){
    @media (max-width: 767px) and (min-width: 641px){ @content }
  }

  @else if ($point == xxs){
    @media (max-width: 640px) and (min-width: 481px){ @content }
  }

  @else if ($point == xxxs){
    @media  (max-width: 480px){ @content }
  }

  @else if ($point == gtmd){
    @media (min-width: 641px){ @content }
  }

  @else if ($point == gtxs){
    @media (min-width: 481px){ @content }
  }

  @else if ($point == ltxs){
    @media  (max-width: 480px){ @content }
  }
}

@include breakpoint(gtxs) {
  .home-masionary-first-col-first-row {
    height: 30%;
  }
  .home-masionary-first-col-second-row {
    height: 70%;
  }
  .home-about-us {
    .cust-table{
      display: table;
      .cust-table-cell{
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}
@include breakpoint(ltxs) {
  .home-masionary-first-col-first-row {
    height: auto;
  }
  .home-masionary-first-col-second-row {
    height: auto;
  }
  .home-masionary-first-col-first-row {
    div[class*="col"]{
     padding: 0;
    }
  }
  .home-masionary-first-col-second-row {
    div[class*="col"] {
      padding: 0;
    }
  }
  .card.card-inverse.card-danger.justify-content-center {
    min-height: auto !important;
  }

  .home-about-us {
    .cust-table {
      display: flex;
      flex-flow: column;
      .cust-table-cell {
        display: block;
      }
    }
  }

  section.container-fluid.bg-articles {
    height: 7em;
    .text-white{
      font-size: 2em;
    }
  }



}

