@charset "UTF-8";
/* Vendor */
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bbck.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../../fonts/fa-brands-400.eot");
  src: url("../../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../../fonts/fa-brands-400.woff2") format("woff2"), url("../../fonts/fa-brands-400.woff") format("woff"), url("../../fonts/fa-brands-400.ttf") format("truetype"), url("../../fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../../fonts/fa-regular-400.eot");
  src: url("../../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../../fonts/fa-regular-400.woff2") format("woff2"), url("../../fonts/fa-regular-400.woff") format("woff"), url("../../fonts/fa-regular-400.ttf") format("truetype"), url("../../fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../../fonts/fa-solid-900.eot");
  src: url("../../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../../fonts/fa-solid-900.woff2") format("woff2"), url("../../fonts/fa-solid-900.woff") format("woff"), url("../../fonts/fa-solid-900.ttf") format("truetype"), url("../../fonts/fa-solid-900.svg#fontawesome") format("svg");
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #42b07a;
  --teal: #42b07a;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #42b07a;
  --secondary: #6c757d;
  --success: #42b07a;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #888888;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Open Sans", "Font Awesome 5 Free", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  /*background: rgb(255,255,255);
  background: linear-gradient( rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%, rgba(40,167,69,0.21332282913165268) 100%);*/
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Font Awesome 5 Free", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #42b07a;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #42b07a;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #2d7853;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  /*padding: $mark-padding;*/
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cae9da;
}

.table-hover .table-primary:hover {
  background-color: #b8e2cd;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b8e2cd;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cae9da;
}

.table-hover .table-success:hover {
  background-color: #b8e2cd;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b8e2cd;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #dedede;
}

.table-hover .table-light:hover {
  background-color: #d1d1d1;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #d1d1d1;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #99d9b9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #42b07a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(66, 176, 122, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #42b07a;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #42b07a;
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #42b07a;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #42b07a;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #99d9b9;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #61c493;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(66, 176, 122, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #42b07a;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #42b07a;
  border-color: #42b07a;
}

.btn-primary:hover {
  color: #fff;
  background-color: #389467;
  border-color: #348b60;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #42b07a;
  border-color: #42b07a;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #348b60;
  border-color: #31825a;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #42b07a;
  border-color: #42b07a;
}

.btn-success:hover {
  color: #fff;
  background-color: #389467;
  border-color: #348b60;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #42b07a;
  border-color: #42b07a;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #348b60;
  border-color: #31825a;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #fff;
  background-color: #888888;
  border-color: #888888;
}

.btn-light:hover {
  color: #fff;
  background-color: #757575;
  border-color: #6f6e6e;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(136, 136, 136, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #fff;
  background-color: #888888;
  border-color: #888888;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #6f6e6e;
  border-color: #686868;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(136, 136, 136, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #42b07a;
  background-color: transparent;
  background-image: none;
  border-color: #42b07a;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #42b07a;
  border-color: #42b07a;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #42b07a;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #42b07a;
  border-color: #42b07a;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #42b07a;
  background-color: transparent;
  background-image: none;
  border-color: #42b07a;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #42b07a;
  border-color: #42b07a;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #42b07a;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #42b07a;
  border-color: #42b07a;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #888888;
  background-color: transparent;
  background-image: none;
  border-color: #888888;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #888888;
  border-color: #888888;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(136, 136, 136, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #888888;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #888888;
  border-color: #888888;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(136, 136, 136, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #42b07a;
  background-color: transparent;
}

.btn-link:hover {
  color: #2d7853;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529 !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #42b07a;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #42b07a;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #42b07a;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(66, 176, 122, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #bee7d3;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #42b07a;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #42b07a;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(66, 176, 122, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(66, 176, 122, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #42b07a;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(66, 176, 122, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #99d9b9;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(153, 217, 185, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-control {
  border-color: #99d9b9;
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.25);
}

.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #99d9b9;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem + 2px) - 1px * 2);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #42b07a;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
  color: #fff;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  /*background-color: $breadcrumb-bg;*/
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.pagination li {
  background: #fff;
  margin: 0.25em;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #42b07a;
}

.pagination li a {
  color: #42b07a;
  padding: 0.9em 1em;
  line-height: 100%;
}

.pagination li.active {
  background: #42b07a;
}

.pagination li.active a {
  color: #fff;
}

section.container-fluid.bg-articles {
  background: url(https://res.cloudinary.com/dbwozcf0d/image/upload/v1613616957/infantformula/Infant_Formula_FAQs_zf6cvm.png);
  text-align: center;
  height: 30em;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-flow: column;
  vertical-align: middle;
  justify-content: center;
  position: relative;
}

section.container-fluid.bg-articles .text-white {
  font-size: 3em;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

section.container-fluid.bg-articles > :after {
  content: " ";
  display: block;
  background: #11111175;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

section.container-fluid.bg-faq {
  background: url(https://res.cloudinary.com/dbwozcf0d/image/upload/v1613617309/infantformula/Infant_Formula_Blogs_pne0x5.png);
  background-position: center center;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #42b07a;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  color: #2d7853;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(66, 176, 122, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #42b07a;
  border-color: #42b07a;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #42b07a;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #348b60;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #42b07a;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #348b60;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #fff;
  background-color: #888888;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #6f6e6e;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #225c3f;
  background-color: #d9efe4;
  border-color: #cae9da;
}

.alert-primary hr {
  border-top-color: #b8e2cd;
}

.alert-primary .alert-link {
  color: #143726;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #225c3f;
  background-color: #d9efe4;
  border-color: #cae9da;
}

.alert-success hr {
  border-top-color: #b8e2cd;
}

.alert-success .alert-link {
  color: #143726;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #474747;
  background-color: #e7e7e7;
  border-color: #dedede;
}

.alert-light hr {
  border-top-color: #d1d1d1;
}

.alert-light .alert-link {
  color: #2e2d2d;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #42b07a;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #888888;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #42b07a;
  border-color: #42b07a;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #225c3f;
  background-color: #cae9da;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #225c3f;
  background-color: #b8e2cd;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #225c3f;
  border-color: #225c3f;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #225c3f;
  background-color: #cae9da;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #225c3f;
  background-color: #b8e2cd;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #225c3f;
  border-color: #225c3f;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #474747;
  background-color: #dedede;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #474747;
  background-color: #d1d1d1;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #474747;
  border-color: #474747;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Font Awesome 5 Free", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Font Awesome 5 Free", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #42b07a !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #348b60 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #42b07a !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #348b60 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #888888 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #6f6e6e !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-foan {
  background-color: #f7f7f7 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #42b07a !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #42b07a !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #888888 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-green {
  color: #35af7b !important;
}

.text-gray {
  color: #888888 !important;
}

.text-primary {
  color: #42b07a !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #348b60 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #42b07a !important;
}

a.text-success:hover, a.text-success:focus {
  color: #348b60 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #888888 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #6f6e6e !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bbck.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../../fonts/fa-brands-400.eot");
  src: url("../../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../../fonts/fa-brands-400.woff2") format("woff2"), url("../../fonts/fa-brands-400.woff") format("woff"), url("../../fonts/fa-brands-400.ttf") format("truetype"), url("../../fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../../fonts/fa-regular-400.eot");
  src: url("../../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../../fonts/fa-regular-400.woff2") format("woff2"), url("../../fonts/fa-regular-400.woff") format("woff"), url("../../fonts/fa-regular-400.ttf") format("truetype"), url("../../fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../../fonts/fa-solid-900.eot");
  src: url("../../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../../fonts/fa-solid-900.woff2") format("woff2"), url("../../fonts/fa-solid-900.woff") format("woff"), url("../../fonts/fa-solid-900.ttf") format("truetype"), url("../../fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

/*!
 * Bootstrap Reboot v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bbck.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../../fonts/fa-brands-400.eot");
  src: url("../../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../../fonts/fa-brands-400.woff2") format("woff2"), url("../../fonts/fa-brands-400.woff") format("woff"), url("../../fonts/fa-brands-400.ttf") format("truetype"), url("../../fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../../fonts/fa-regular-400.eot");
  src: url("../../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../../fonts/fa-regular-400.woff2") format("woff2"), url("../../fonts/fa-regular-400.woff") format("woff"), url("../../fonts/fa-regular-400.ttf") format("truetype"), url("../../fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../../fonts/fa-solid-900.eot");
  src: url("../../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../../fonts/fa-solid-900.woff2") format("woff2"), url("../../fonts/fa-solid-900.woff") format("woff"), url("../../fonts/fa-solid-900.ttf") format("truetype"), url("../../fonts/fa-solid-900.svg#fontawesome") format("svg");
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  /*background: rgb(255,255,255);
  background: linear-gradient( rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%, rgba(40,167,69,0.21332282913165268) 100%);*/
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Font Awesome 5 Free", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #42b07a;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #42b07a;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #2d7853;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

@font-face {
  font-family: 'FontAwesome';
  src: url("../../fonts/fontawesome-webfont.eot?v=4.5.0");
  src: url("../../fonts/fontawesome-webfont.eot?#iefix&v=4.5.0") format("embedded-opentype"), url("../../fonts/fontawesome-webfont.woff2?v=4.5.0") format("woff2"), url("../../fonts/fontawesome-webfont.woff?v=4.5.0") format("woff"), url("../../fonts/fontawesome-webfont.ttf?v=4.5.0") format("truetype"), url("../../fonts/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-lg {
  font-size: 1.33333333em;
  line-height: .75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571429em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: .14285714em;
  text-align: center;
}

.fa-li.fa-lg {
  left: -1.85714286em;
}

.fa-border {
  padding: .2em .25em .15em;
  border: solid .08em #eee;
  border-radius: .1em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right {
  margin-left: .3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: .3em;
}

.fa.pull-right {
  margin-left: .3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-glass:before {
  content: "\f000";
}

.fa-music:before {
  content: "\f001";
}

.fa-search:before {
  content: "\f002";
}

.fa-envelope-o:before {
  content: "\f003";
}

.fa-heart:before {
  content: "\f004";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-o:before {
  content: "\f006";
}

.fa-user:before {
  content: "\f007";
}

.fa-film:before {
  content: "\f008";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-check:before {
  content: "\f00c";
}

.fa-remove:before, .fa-close:before, .fa-times:before {
  content: "\f00d";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-signal:before {
  content: "\f012";
}

.fa-gear:before, .fa-cog:before {
  content: "\f013";
}

.fa-trash-o:before {
  content: "\f014";
}

.fa-home:before {
  content: "\f015";
}

.fa-file-o:before {
  content: "\f016";
}

.fa-clock-o:before {
  content: "\f017";
}

.fa-road:before {
  content: "\f018";
}

.fa-download:before {
  content: "\f019";
}

.fa-arrow-circle-o-down:before {
  content: "\f01a";
}

.fa-arrow-circle-o-up:before {
  content: "\f01b";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-play-circle-o:before {
  content: "\f01d";
}

.fa-rotate-right:before, .fa-repeat:before {
  content: "\f01e";
}

.fa-refresh:before {
  content: "\f021";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-lock:before {
  content: "\f023";
}

.fa-flag:before {
  content: "\f024";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-print:before {
  content: "\f02f";
}

.fa-camera:before {
  content: "\f030";
}

.fa-font:before {
  content: "\f031";
}

.fa-bold:before {
  content: "\f032";
}

.fa-italic:before {
  content: "\f033";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-list:before {
  content: "\f03a";
}

.fa-dedent:before, .fa-outdent:before {
  content: "\f03b";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-video-camera:before {
  content: "\f03d";
}

.fa-photo:before, .fa-image:before, .fa-picture-o:before {
  content: "\f03e";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-tint:before {
  content: "\f043";
}

.fa-edit:before, .fa-pencil-square-o:before {
  content: "\f044";
}

.fa-share-square-o:before {
  content: "\f045";
}

.fa-check-square-o:before {
  content: "\f046";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-play:before {
  content: "\f04b";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-eject:before {
  content: "\f052";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-times-circle-o:before {
  content: "\f05c";
}

.fa-check-circle-o:before {
  content: "\f05d";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-mail-forward:before, .fa-share:before {
  content: "\f064";
}

.fa-expand:before {
  content: "\f065";
}

.fa-compress:before {
  content: "\f066";
}

.fa-plus:before {
  content: "\f067";
}

.fa-minus:before {
  content: "\f068";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-warning:before, .fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-plane:before {
  content: "\f072";
}

.fa-calendar:before {
  content: "\f073";
}

.fa-random:before {
  content: "\f074";
}

.fa-comment:before {
  content: "\f075";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-bar-chart-o:before, .fa-bar-chart:before {
  content: "\f080";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-key:before {
  content: "\f084";
}

.fa-gears:before, .fa-cogs:before {
  content: "\f085";
}

.fa-comments:before {
  content: "\f086";
}

.fa-thumbs-o-up:before {
  content: "\f087";
}

.fa-thumbs-o-down:before {
  content: "\f088";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-heart-o:before {
  content: "\f08a";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-linkedin-square:before {
  content: "\f08c";
}

.fa-thumb-tack:before {
  content: "\f08d";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-upload:before {
  content: "\f093";
}

.fa-lemon-o:before {
  content: "\f094";
}

.fa-phone:before {
  content: "\f095";
}

.fa-square-o:before {
  content: "\f096";
}

.fa-bookmark-o:before {
  content: "\f097";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook-f:before, .fa-facebook:before {
  content: "\f09a";
}

.fa-github:before {
  content: "\f09b";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-feed:before, .fa-rss:before {
  content: "\f09e";
}

.fa-hdd-o:before {
  content: "\f0a0";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-hand-o-right:before {
  content: "\f0a4";
}

.fa-hand-o-left:before {
  content: "\f0a5";
}

.fa-hand-o-up:before {
  content: "\f0a6";
}

.fa-hand-o-down:before {
  content: "\f0a7";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-group:before, .fa-users:before {
  content: "\f0c0";
}

.fa-chain:before, .fa-link:before {
  content: "\f0c1";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-cut:before, .fa-scissors:before {
  content: "\f0c4";
}

.fa-copy:before, .fa-files-o:before {
  content: "\f0c5";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-save:before, .fa-floppy-o:before {
  content: "\f0c7";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-navicon:before, .fa-reorder:before, .fa-bars:before {
  content: "\f0c9";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-plus:before {
  content: "\f0d5";
}

.fa-money:before {
  content: "\f0d6";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-unsorted:before, .fa-sort:before {
  content: "\f0dc";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "\f0dd";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "\f0de";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-linkedin:before {
  content: "\f0e1";
}

.fa-rotate-left:before, .fa-undo:before {
  content: "\f0e2";
}

.fa-legal:before, .fa-gavel:before {
  content: "\f0e3";
}

.fa-dashboard:before, .fa-tachometer:before {
  content: "\f0e4";
}

.fa-comment-o:before {
  content: "\f0e5";
}

.fa-comments-o:before {
  content: "\f0e6";
}

.fa-flash:before, .fa-bolt:before {
  content: "\f0e7";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-paste:before, .fa-clipboard:before {
  content: "\f0ea";
}

.fa-lightbulb-o:before {
  content: "\f0eb";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-bell-o:before {
  content: "\f0a2";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cutlery:before {
  content: "\f0f5";
}

.fa-file-text-o:before {
  content: "\f0f6";
}

.fa-building-o:before {
  content: "\f0f7";
}

.fa-hospital-o:before {
  content: "\f0f8";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-mobile-phone:before, .fa-mobile:before {
  content: "\f10b";
}

.fa-circle-o:before {
  content: "\f10c";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-circle:before {
  content: "\f111";
}

.fa-mail-reply:before, .fa-reply:before {
  content: "\f112";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-folder-o:before {
  content: "\f114";
}

.fa-folder-open-o:before {
  content: "\f115";
}

.fa-smile-o:before {
  content: "\f118";
}

.fa-frown-o:before {
  content: "\f119";
}

.fa-meh-o:before {
  content: "\f11a";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-keyboard-o:before {
  content: "\f11c";
}

.fa-flag-o:before {
  content: "\f11d";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-code:before {
  content: "\f121";
}

.fa-mail-reply-all:before, .fa-reply-all:before {
  content: "\f122";
}

.fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
  content: "\f123";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-crop:before {
  content: "\f125";
}

.fa-code-fork:before {
  content: "\f126";
}

.fa-unlink:before, .fa-chain-broken:before {
  content: "\f127";
}

.fa-question:before {
  content: "\f128";
}

.fa-info:before {
  content: "\f129";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-shield:before {
  content: "\f132";
}

.fa-calendar-o:before {
  content: "\f133";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-minus-square-o:before {
  content: "\f147";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-pencil-square:before {
  content: "\f14b";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-toggle-down:before, .fa-caret-square-o-down:before {
  content: "\f150";
}

.fa-toggle-up:before, .fa-caret-square-o-up:before {
  content: "\f151";
}

.fa-toggle-right:before, .fa-caret-square-o-right:before {
  content: "\f152";
}

.fa-euro:before, .fa-eur:before {
  content: "\f153";
}

.fa-gbp:before {
  content: "\f154";
}

.fa-dollar:before, .fa-usd:before {
  content: "\f155";
}

.fa-rupee:before, .fa-inr:before {
  content: "\f156";
}

.fa-cny:before, .fa-rmb:before, .fa-yen:before, .fa-jpy:before {
  content: "\f157";
}

.fa-ruble:before, .fa-rouble:before, .fa-rub:before {
  content: "\f158";
}

.fa-won:before, .fa-krw:before {
  content: "\f159";
}

.fa-bitcoin:before, .fa-btc:before {
  content: "\f15a";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-text:before {
  content: "\f15c";
}

.fa-sort-alpha-asc:before {
  content: "\f15d";
}

.fa-sort-alpha-desc:before {
  content: "\f15e";
}

.fa-sort-amount-asc:before {
  content: "\f160";
}

.fa-sort-amount-desc:before {
  content: "\f161";
}

.fa-sort-numeric-asc:before {
  content: "\f162";
}

.fa-sort-numeric-desc:before {
  content: "\f163";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-youtube-square:before {
  content: "\f166";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-youtube-play:before {
  content: "\f16a";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-adn:before {
  content: "\f170";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitbucket-square:before {
  content: "\f172";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-apple:before {
  content: "\f179";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-android:before {
  content: "\f17b";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-trello:before {
  content: "\f181";
}

.fa-female:before {
  content: "\f182";
}

.fa-male:before {
  content: "\f183";
}

.fa-gittip:before, .fa-gratipay:before {
  content: "\f184";
}

.fa-sun-o:before {
  content: "\f185";
}

.fa-moon-o:before {
  content: "\f186";
}

.fa-archive:before {
  content: "\f187";
}

.fa-bug:before {
  content: "\f188";
}

.fa-vk:before {
  content: "\f189";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-arrow-circle-o-right:before {
  content: "\f18e";
}

.fa-arrow-circle-o-left:before {
  content: "\f190";
}

.fa-toggle-left:before, .fa-caret-square-o-left:before {
  content: "\f191";
}

.fa-dot-circle-o:before {
  content: "\f192";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-turkish-lira:before, .fa-try:before {
  content: "\f195";
}

.fa-plus-square-o:before {
  content: "\f196";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-slack:before {
  content: "\f198";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-institution:before, .fa-bank:before, .fa-university:before {
  content: "\f19c";
}

.fa-mortar-board:before, .fa-graduation-cap:before {
  content: "\f19d";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-pied-piper:before {
  content: "\f1a7";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-spoon:before {
  content: "\f1b1";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-automobile:before, .fa-car:before {
  content: "\f1b9";
}

.fa-cab:before, .fa-taxi:before {
  content: "\f1ba";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-file-pdf-o:before {
  content: "\f1c1";
}

.fa-file-word-o:before {
  content: "\f1c2";
}

.fa-file-excel-o:before {
  content: "\f1c3";
}

.fa-file-powerpoint-o:before {
  content: "\f1c4";
}

.fa-file-photo-o:before, .fa-file-picture-o:before, .fa-file-image-o:before {
  content: "\f1c5";
}

.fa-file-zip-o:before, .fa-file-archive-o:before {
  content: "\f1c6";
}

.fa-file-sound-o:before, .fa-file-audio-o:before {
  content: "\f1c7";
}

.fa-file-movie-o:before, .fa-file-video-o:before {
  content: "\f1c8";
}

.fa-file-code-o:before {
  content: "\f1c9";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-life-bouy:before, .fa-life-buoy:before, .fa-life-saver:before, .fa-support:before, .fa-life-ring:before {
  content: "\f1cd";
}

.fa-circle-o-notch:before {
  content: "\f1ce";
}

.fa-ra:before, .fa-rebel:before {
  content: "\f1d0";
}

.fa-ge:before, .fa-empire:before {
  content: "\f1d1";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-y-combinator-square:before, .fa-yc-square:before, .fa-hacker-news:before {
  content: "\f1d4";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-wechat:before, .fa-weixin:before {
  content: "\f1d7";
}

.fa-send:before, .fa-paper-plane:before {
  content: "\f1d8";
}

.fa-send-o:before, .fa-paper-plane-o:before {
  content: "\f1d9";
}

.fa-history:before {
  content: "\f1da";
}

.fa-circle-thin:before {
  content: "\f1db";
}

.fa-header:before {
  content: "\f1dc";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-sliders:before {
  content: "\f1de";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-soccer-ball-o:before, .fa-futbol-o:before {
  content: "\f1e3";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-newspaper-o:before {
  content: "\f1ea";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bell-slash-o:before {
  content: "\f1f7";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-eyedropper:before {
  content: "\f1fb";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-area-chart:before {
  content: "\f1fe";
}

.fa-pie-chart:before {
  content: "\f200";
}

.fa-line-chart:before {
  content: "\f201";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bus:before {
  content: "\f207";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-cc:before {
  content: "\f20a";
}

.fa-shekel:before, .fa-sheqel:before, .fa-ils:before {
  content: "\f20b";
}

.fa-meanpath:before {
  content: "\f20c";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-venus:before {
  content: "\f221";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-intersex:before, .fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-facebook-official:before {
  content: "\f230";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-server:before {
  content: "\f233";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-hotel:before, .fa-bed:before {
  content: "\f236";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-train:before {
  content: "\f238";
}

.fa-subway:before {
  content: "\f239";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-yc:before, .fa-y-combinator:before {
  content: "\f23b";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-battery-4:before, .fa-battery-full:before {
  content: "\f240";
}

.fa-battery-3:before, .fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battery-2:before, .fa-battery-half:before {
  content: "\f242";
}

.fa-battery-1:before, .fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-0:before, .fa-battery-empty:before {
  content: "\f244";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-sticky-note-o:before {
  content: "\f24a";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-hourglass-o:before {
  content: "\f250";
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
  content: "\f251";
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hand-grab-o:before, .fa-hand-rock-o:before {
  content: "\f255";
}

.fa-hand-stop-o:before, .fa-hand-paper-o:before {
  content: "\f256";
}

.fa-hand-scissors-o:before {
  content: "\f257";
}

.fa-hand-lizard-o:before {
  content: "\f258";
}

.fa-hand-spock-o:before {
  content: "\f259";
}

.fa-hand-pointer-o:before {
  content: "\f25a";
}

.fa-hand-peace-o:before {
  content: "\f25b";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-safari:before {
  content: "\f267";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-tv:before, .fa-television:before {
  content: "\f26c";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-500px:before {
  content: "\f26e";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-calendar-plus-o:before {
  content: "\f271";
}

.fa-calendar-minus-o:before {
  content: "\f272";
}

.fa-calendar-times-o:before {
  content: "\f273";
}

.fa-calendar-check-o:before {
  content: "\f274";
}

.fa-industry:before {
  content: "\f275";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-map-o:before {
  content: "\f278";
}

.fa-map:before {
  content: "\f279";
}

.fa-commenting:before {
  content: "\f27a";
}

.fa-commenting-o:before {
  content: "\f27b";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-vimeo:before {
  content: "\f27d";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-edge:before {
  content: "\f282";
}

.fa-credit-card-alt:before {
  content: "\f283";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-modx:before {
  content: "\f285";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-usb:before {
  content: "\f287";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-pause-circle-o:before {
  content: "\f28c";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stop-circle-o:before {
  content: "\f28e";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-percent:before {
  content: "\f295";
}

@media (min-width: 481px) {
  .home-masionary-first-col-first-row {
    height: 30%;
  }
  .home-masionary-first-col-second-row {
    height: 70%;
  }
  .home-about-us .cust-table {
    display: table;
  }
  .home-about-us .cust-table .cust-table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

@media (max-width: 480px) {
  .home-masionary-first-col-first-row {
    height: auto;
  }
  .home-masionary-first-col-second-row {
    height: auto;
  }
  .home-masionary-first-col-first-row div[class*="col"] {
    padding: 0;
  }
  .home-masionary-first-col-second-row div[class*="col"] {
    padding: 0;
  }
  .card.card-inverse.card-danger.justify-content-center {
    min-height: auto !important;
  }
  .home-about-us .cust-table {
    display: flex;
    flex-flow: column;
  }
  .home-about-us .cust-table .cust-table-cell {
    display: block;
  }
  section.container-fluid.bg-articles {
    height: 7em;
  }
  section.container-fluid.bg-articles .text-white {
    font-size: 2em;
  }
}

.bg-green-site {
  background: #35af7b;
}

div#navbarSupportedContent {
  width: 100%;
  border-top: 2px solid #888888;
}

.nav-container .border-top-gray.active {
  border-top: 2px solid #35af7b;
  margin-top: -2px;
}

.nav-container .border-top-gray:hover {
  border-top: 2px solid #35af7b;
  margin-top: -2px;
}

.nav-container .border-top-gray a {
  color: #888888;
}

.border-bottom-gray {
  border-bottom: 1px solid #dbdbdb;
}

.border-bottom-gray.active a, .border-bottom-gray.active span {
  color: #43b27b !important;
  font-weight: bold;
}

.border-top-bottom-gray {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

.border-right-gray {
  border-right: 2px solid #cccccc;
}

.home-box {
  max-width: 1400px;
  background-image: url(https://res.cloudinary.com/dbwozcf0d/image/upload/v1612418150/infantformula/IF_Pattern_2_1_wbbowr.png);
  background-size: 350px;
  background-repeat: no-repeat;
  background-position: calc(100% - -27px) 100%;
  position: relative;
}

.container-fluid.home-box > .container:before {
  content: ' ';
  background-image: url(https://res.cloudinary.com/dbwozcf0d/image/upload/v1612418658/infantformula/IF_Pattern_j4u8j4.png);
  background-repeat: no-repeat;
  width: 300px;
  height: 400px;
  display: block;
  position: absolute;
  background-size: contain;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.usp-section .usp-item .content {
  max-width: 200px;
}

.section-title {
  position: relative;
  -js-display: flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.section-title b {
  display: block;
  -ms-flex: 1;
  flex: 1;
  height: 2px;
  opacity: .1;
  background-color: currentColor;
}

.section-title-center span, .section-title-bold-center span {
  text-align: center;
}

button.navbar-toggler {
  background: #35af7b;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.home-about-us {
  background: url("https://res.cloudinary.com/dbwozcf0d/image/upload/v1608178766/infantformula/homepage_u8dljm.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}

.home-about-us div[class*="col"] {
  background: #f2f0ee82;
}

.navbar-toggler-icon {
  background-image: url(https://mdbootstrap.com/img/svg/hamburger5.svg?);
}

.home-masinory {
  /*.image-1 {
    background: url("/storage/app/media/theme/image-1.jpg");
  }
  .image-2 {
    background: url("/storage/app/media/theme/image-2.jpg");
  }
  .image-3 {
    background: url("/storage/app/media/theme/image-3.jpg");
  }
  .image-4 {
    background: url("/storage/app/media/theme/image-4.jpg");
  }
  .image-5 {
    background: url("/storage/app/media/theme/image-5.jpg");
  }*/
}

.home-masinory div[class*="image"] {
  background-size: cover !important;
  background-position: center center !important;
}

.home-masinory .card-block {
  color: #fff;
  z-index: 9;
  width: 100%;
}

.home-masinory .masionary-button {
  border-color: #fff;
  color: #fff;
}

.home-masinory .card.card-inverse {
  width: 100%;
}

.home-masinory .card.card-inverse:after {
  background-color: #0000005e;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.faq-section .faq-item p {
  max-width: 200px;
  margin: 0 auto;
}

.cust-button {
  border-radius: 20px;
  border: 1px solid #35af7b;
  margin: 0 auto;
  color: #35af7b;
  padding: 0.35em 1.5em;
}

.latest-review .h-100 {
  background: #fff;
}

.fa {
  color: #42b07a;
}

.latest-review.h-100 {
  background: #fff;
}

.owl-theme .owl-controls .owl-page {
  display: inline-block;
}

.owl-theme .owl-controls .owl-page span {
  background: none repeat scroll 0 0 #42b07a;
  border-radius: 20px;
  display: block;
  height: 12px;
  margin: 5px 7px;
  opacity: 0.5;
  width: 12px;
}

.owl-theme .owl-controls .owl-pagination {
  text-align: center;
}

.top-rated-container {
  position: absolute;
  background: #FFF;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  text-align: left;
}

.table-container {
  display: table;
  width: 100%;
  height: 100%;
}

.footer ul.fooer-list-menu {
  text-align: center;
  padding: 0;
}

.footer ul.fooer-list-menu li {
  display: inline-block;
  margin: 0 auto;
  padding: 0.25em 1em;
}

.footer ul.fooer-list-menu li a {
  color: #888888;
}

.footer .footer-copyright {
  color: #888888;
  text-align: center;
  margin-bottom: 2em;
}

.products-list li {
  list-style: none;
}

/*.gradiant-gray-1 {
  background: url(/storage/app/media/theme/gradiant-gray-1.png);
  background-repeat: repeat-y ;
  background-size: 100%;
  border-bottom-left-radius: 3%;
  border-bottom-right-radius: 3%;
  padding: 2em 0em;
}*/
.gradiant-gray-1 {
  background-image: url(/storage/app/media/theme/xo.png);
  background-repeat: repeat-y;
  border-bottom-left-radius: 3%;
  border-bottom-right-radius: 3%;
  padding: 2em 0em;
  background-color: #f7f7f7;
  background-size: 212px;
  background-position: calc(100% - -27px) 50%;
  background-repeat: no-repeat;
}

.container p {
  color: #888888;
}

.gradiant-gray-2 {
  /*background: #f5f5f5;*/
}

.single-product {
  /* .owl-carousel{
    display: none;
    width: 100%;
    text-align:center;
    .owl-item {
    float: none !important;
    position: relative;
    min-height: 1px;
    display:inline-block;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    }
  }
  .owl-wrapper {
    width: auto !important;
  }*/
}

.single-product h1 {
  color: #42b07a;
  font-weight: bold;
}

.single-product h2 {
  color: #42b07a;
  font-weight: bold;
}

.single-product h3 {
  color: #42b07a;
  font-weight: bold;
}

.border-left-green {
  border-left: 3px solid #42b07a !important;
}

.border-bottom-green {
  border-bottom: 3px solid #42b07a !important;
}

.dropshadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.dropshadow:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

nav.navbar {
  padding-left: 0;
  padding-right: 0;
}

.contact-us-page .contact-form .form-control {
  padding: 1em;
  border: 1px solid #35af7b;
}

.contact-us-page .fa {
  font-size: 4rem;
  color: #efef00;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown > .dropdown-toggle:active {
  pointer-events: none;
}

i.fa.fa-bars {
  color: #42af78;
}

.bottom-blog-panel .previous, .bottom-blog-panel .next {
  background: #fff;
  color: #333;
  position: relative;
}

.top-rated-image {
  height: 100px;
}

.user-comment-box {
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 25px;
  border-radius: 0px 30px 30px;
}

span.fa-layers.fa-fw, svg.iconify, .star-raiting {
  color: #35af7b;
}

.ss-result {
  margin-bottom: 2em;
}

.ss-result__aside {
  float: right;
  margin-left: .5em;
}

.ss-result__title {
  font-weight: bold;
  margin-bottom: .5em;
}

.ss-result__badge {
  font-size: .7em;
  padding: .2em .5em;
  border-radius: 4px;
  margin-left: .75em;
  background: #eee;
  display: inline-block;
}

.ss-result__text {
  margin-bottom: .5em;
}

.ss-result__aside img {
  height: 133px;
}

.col-4.h-100 img {
  width: auto !important;
  max-height: 100px !important;
}

form.review-form.p-5 {
  border: 1px solid #42b07a;
}

.row-eq-height {
  display: inline-flex;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  box-sizing: border-box;
}

.post-list > div {
  margin-top: 3em !important;
}

.post-list > div:first-child {
  margin-top: 0em !important;
}

h5.sidebar-heading {
  color: #a4a4a4;
  font-weight: 800;
}

.no-style {
  list-style: none !important;
}

h5.sidebar-heading:after {
  content: ' ';
  display: block;
  border-top: 3px solid #a4a4a4;
  width: 41px;
  margin-top: 6px;
}

section.sidebar ul :last-child {
  border: 0px;
}

img.blogs-image {
  max-width: 500px;
}

.brand-logo-container {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  flex-flow: column;
}

label.advertisement-text {
  margin: 0em;
  font-size: 0.75em;
  font-weight: bold;
}

/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */
/* clearfix */
.owl-carousel .owl-wrapper:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

/* display none until init */
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item {
  float: left;
}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer;
}

.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing {
  cursor: url(grabbing.png) 8 8, move;
}

/* fix */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

/* UberMenu - Copyright 2011-2016, SevenSpark */
.ubermenu-skin-aqua {
  font-size: 12px;
  color: #888888;
  /* border: 1px solid #71c275;*/
  background-color: #03b28f;
  /*background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #03b28f), color-stop(50%, #03b28f), color-stop(50%, #00936c));
  background: -webkit-linear-gradient(top, #03b28f, #00936c);
  background: -moz-linear-gradient(top, #03b28f, #00936c);
  background: -ms-linear-gradient(top, #03b28f, #00936c);
  background: -o-linear-gradient(top, #03b28f, #00936c);*/
  /*-webkit-box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);*/
  background-image: url("//infantformula.com.au/wp-content/themes/InDigitalMedia/images/Layer-111.jpg");
}

.ubermenu-skin-aqua .ubermenu-target {
  color: #555555;
}

.ubermenu-skin-aqua .ubermenu-target-description {
  color: #aaaaaa;
}

.ubermenu-skin-aqua.ubermenu-responsive-toggle {
  background: #fff;
  color: #888888;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.ubermenu-skin-aqua.ubermenu-responsive-toggle:hover {
  background: #fff;
  color: #888888;
}

.ubermenu-skin-aqua .ubermenu-item-level-0.ubermenu-current-menu-item > .ubermenu-target, .ubermenu-skin-aqua .ubermenu-item-level-0.ubermenu-current-menu-parent > .ubermenu-target, .ubermenu-skin-aqua .ubermenu-item-level-0.ubermenu-current-menu-ancestor > .ubermenu-target {
  color: #888888;
  background: #fff;
}

.ubermenu-skin-aqua .ubermenu-item.ubermenu-item-level-0 > .ubermenu-highlight {
  color: #4d90fe;
  background: transparent;
}

.ubermenu-skin-aqua.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-target {
  background-color: #03b28f;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #03b28f), color-stop(50%, #03b28f), color-stop(50%, #00936c));
  background: -webkit-linear-gradient(top, #03b28f, #00936c);
  background: -moz-linear-gradient(top, #03b28f, #00936c);
  background: -ms-linear-gradient(top, #03b28f, #00936c);
  background: -o-linear-gradient(top, #03b28f, #00936c);
  -webkit-box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.1);
}

.ubermenu-skin-aqua .ubermenu-item-level-0 > .ubermenu-target {
  font-weight: bold;
  color: #888888;
  text-transform: uppercase;
  /*border-left: 1px solid #000000;*/
  -webkit-box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.1);
}

body:not(.rtl) .ubermenu-skin-aqua.ubermenu-horizontal .ubermenu-item-level-0:first-child > .ubermenu-target {
  box-shadow: none;
}

.ubermenu-skin-aqua .ubermenu-item-level-0:hover > .ubermenu-target, .ubermenu-skin-aqua .ubermenu-item-level-0.ubermenu-active > .ubermenu-target {
  background: #ffffff;
  color: #888888;
}

.ubermenu-skin-aqua .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item > .ubermenu-target {
  color: #888888;
}

.ubermenu-skin-aqua .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item > .ubermenu-target:hover {
  color: #888888;
}

.ubermenu-skin-aqua .ubermenu-submenu.ubermenu-submenu-drop {
  background: #fff;
  border: 1px solid #e0e0e0;
}

.ubermenu-skin-aqua.ubermenu-horizontal .ubermenu-item-level-0 > .ubermenu-submenu-drop {
  border-top: none;
}

.ubermenu-skin-aqua .ubermenu-tab-content-panel {
  background: #fff;
}

.ubermenu-skin-aqua .ubermenu-submenu {
  color: #888888;
}

.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-target {
  color: #888888;
}

.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-highlight {
  color: #4d90fe;
}

.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-target:hover, .ubermenu-skin-aqua .ubermenu-submenu .ubermenu-active > .ubermenu-target {
  color: #111111;
}

.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-target > .ubermenu-target-description {
  color: #aaaaaa;
}

.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-active > .ubermenu-target > .ubermenu-target-description {
  color: #666666;
}

.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-item-header > .ubermenu-target {
  font-weight: bold;
  color: #888888;
}

.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-item-header.ubermenu-has-submenu-stack > .ubermenu-target {
  border-bottom: 1px solid #888888;
}

.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-item-header > .ubermenu-target:hover {
  color: #222222;
}

.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-current-menu-item > .ubermenu-target {
  color: #222222;
  background: transparent;
}

.ubermenu-nojs.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-tabs .ubermenu-tab:hover > .ubermenu-target {
  background: #eeeeee;
  color: #111111;
}

.ubermenu-skin-aqua .ubermenu-submenu .ubermenu-tab.ubermenu-active > .ubermenu-target {
  background: #eeeeee;
  color: #111111;
}

.ubermenu-skin-aqua .ubermenu-tabs-group, .ubermenu-skin-aqua .ubermenu-tab > .ubermenu-target, .ubermenu-skin-aqua .ubermenu-tab-content-panel {
  border-color: #eeeeee;
}

.ubermenu-skin-aqua .ubermenu-submenu-drop {
  border-bottom-width: 3px;
}

.ubermenu-skin-aqua .ubermenu-item .ubermenu-submenu-drop {
  -webkit-box-sizing: content-box;
  /* Takes into account borders */
  -moz-box-sizing: content-box;
  /* Takes into account borders */
  -o-box-sizing: content-box;
  /* Takes into account borders */
  box-sizing: content-box;
  /* Takes into account borders */
}

.ubermenu-skin-aqua.ubermenu-horizontal .ubermenu-submenu-drop.ubermenu-submenu-align-left_edge_bar, .ubermenu-skin-aqua.ubermenu-horizontal .ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
  left: -1px;
  /* for borders */
}

.ubermenu-skin-aqua.ubermenu-horizontal .ubermenu-submenu-drop.ubermenu-submenu-align-right_edge_bar {
  right: -1px;
  /* for borders */
}

.ubermenu-skin-aqua.ubermenu-horizontal .ubermenu-submenu-drop.ubermenu-submenu-align-full_width.ubermenu-submenu-padded {
  width: calc(103%);
  /* because we use box-sizing:border-box */
}

.ubermenu-skin-aqua.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-submenu-drop, .ubermenu-skin-aqua.ubermenu-nojs.ubermenu-vertical .ubermenu-item-level-0:hover > .ubermenu-submenu-drop {
  top: -1px;
}

.ubermenu-skin-aqua.ubermenu-vertical .ubermenu-item-level-0.ubermenu-active > .ubermenu-submenu-drop, .ubermenu-skin-aqua.ubermenu-vertical .ubermenu-active .ubermenu-submenu.ubermenu-submenu-type-flyout .ubermenu-submenu-type-mega {
  top: -1px;
}

.ubermenu-skin-aqua.ubermenu-horizontal .ubermenu-item-level-0.ubermenu-active:after {
  content: '';
  border-bottom: 1px solid transparent;
  width: 100%;
  width: calc(101%);
  margin-bottom: -1px;
}

.ubermenu-skin-aqua.ubermenu-horizontal .ubermenu-item-level-0.ubermenu-active > .ubermenu-submenu-drop {
  margin-top: 1px;
}

.ubermenu-skin-aqua.ubermenu-horizontal:not(.ubermenu-transition-shift) .ubermenu-item-level-0 > .ubermenu-submenu-drop {
  margin-top: 1px;
}

.ubermenu-skin-aqua.ubermenu-vertical:before {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  height: 100%;
  border-right: 1px solid #000000;
  z-index: 100;
  width: 0;
}

/* Drop Sub - Left Edge of Menu Bar */
/* Drop Sub - Right Edge of Menu Bar */
/*
 * UberMenu 3
 * http://wpmegamenu.com
 * Copyright 2011-2015 Chris Mavricos, SevenSpark
 */
.ubermenu {
  margin: 0;
  padding: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-indent: 0;
  clip: auto;
  position: static;
  background: 0 0;
  text-transform: none;
  overflow: visible;
  z-index: 10;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
  line-height: 1.3em;
  border: none;
  border-radius: 0;
  pointer-events: auto;
}

.ubermenu .ubermenu-column, .ubermenu .ubermenu-divider, .ubermenu .ubermenu-icon, .ubermenu .uubermenu.min.cssbermenu-image, .ubermenu .ubermenu-item, .ubermenu .ubermenu-nav, .ubermenu .ubermenu-retractor, .ubermenu .ubermenu-row, .ubermenu .ubermenu-search, .ubermenu .ubermenu-search-input, .ubermenu .ubermenu-search-submit, .ubermenu .ubermenu-submenu, .ubermenu .ubermenu-submenu-footer, .ubermenu .ubermenu-tabs, .ubermenu .ubermenu-tabs-panels, .ubermenu .ubermenu-target {
  margin: 0;
  padding: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-indent: 0;
  clip: auto;
  position: static;
  background: 0 0;
  text-transform: none;
  overflow: visible;
  z-index: 10;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
  line-height: 1.3em;
  border: none;
  border-radius: 0;
  pointer-events: auto;
}

.ubermenu-responsive-toggle {
  margin: 0;
  padding: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-indent: 0;
  clip: auto;
  position: static;
  background: 0 0;
  text-transform: none;
  overflow: visible;
  z-index: 10;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
  line-height: 1.3em;
  border: none;
  border-radius: 0;
  pointer-events: auto;
}

.ubermenu {
  height: auto;
  width: auto;
  max-height: none;
  min-height: 0;
}

.ubermenu .ubermenu-column, .ubermenu .ubermenu-divider, .ubermenu .ubermenu-item, .ubermenu .ubermenu-nav, .ubermenu .ubermenu-retractor, .ubermenu .ubermenu-row, .ubermenu .ubermenu-search, .ubermenu .ubermenu-search-input, .ubermenu .ubermenu-search-submit, .ubermenu .ubermenu-submenu, .ubermenu .ubermenu-submenu-footer, .ubermenu .ubermenu-tabs, .ubermenu .ubermenu-tabs-panels, .ubermenu .ubermenu-target {
  height: auto;
  width: auto;
  max-height: none;
  min-height: 0;
}

.ubermenu-responsive-toggle {
  height: auto;
  width: auto;
  max-height: none;
  min-height: 0;
}

.ubermenu {
  display: block;
  line-height: 1.3em;
  text-align: left;
}

.ubermenu .ubermenu-image, .ubermenu .ubermenu-submenu-drop {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.ubermenu-bound, .ubermenu-bound-inner .ubermenu-nav {
  position: relative;
}

.ubermenu {
  overflow: visible !important;
  z-index: 100;
}

:focus, :active {
  outline: none;
}

.ubermenu-nav {
  overflow: visible !important;
  z-index: 100;
  text-align: inherit;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-flow: row;
  align-content: center;
  justify-content: space-around;
}

.ubermenu-nav > .ubermenu-item {
  /*background: $teal;*/
  width: 100%;
  max-height: none;
  min-height: 0;
  white-space: nowrap;
}

.ubermenu-row, .ubermenu-submenu {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.ubermenu a.ubermenu-target, .ubermenu-trigger-click .ubermenu-has-submenu-drop > .ubermenu-target {
  cursor: pointer;
}

.ubermenu span.ubermenu-target {
  cursor: default;
}

.ubermenu-accessible .ubermenu-target {
  outline: 0;
}

.ubermenu-accessible input:not(:hover):focus, .ubermenu-accessible:not(:hover) .ubermenu-target:focus {
  outline: #2cc4ff solid 1px;
  box-shadow: 0 0 5px #2CC4FF !important;
  z-index: 50;
  transition: outline 0.1s linear;
}

.ubermenu-responsive-toggle, .ubermenu-sticky-toggle-wrapper {
  display: none;
  text-decoration: none;
  cursor: pointer;
  max-width: 100%;
}

.ubermenu-responsive-toggle:hover {
  text-decoration: none;
}

.ubermenu-responsive-toggle .fa {
  width: 1.3em;
  text-align: center;
  margin-right: 0.6em;
}

.ubermenu-responsive-toggle-icon-only .fa {
  margin-right: 0;
}

.ubermenu-responsive-toggle-align-left {
  float: left;
}

.ubermenu-responsive-toggle-align-right {
  float: right;
}

.ubermenu-responsive-toggle-content-align-left {
  text-align: left;
}

.ubermenu-responsive-toggle-content-align-center {
  text-align: center;
}

.ubermenu-responsive-toggle-content-align-right {
  text-align: right;
}

.ubermenu-bar-align-full {
  clear: both;
}

.ubermenu-bar-align-left {
  float: left;
}

.ubermenu-bar-align-right {
  float: right;
}

.ubermenu-bar-align-center {
  float: none;
  margin: 0 auto;
}

.ubermenu-bar-inner-center .ubermenu-nav {
  margin: 0 auto;
}

.ubermenu-items-align-center {
  text-align: center;
}

.ubermenu-items-align-right {
  text-align: right;
}

.ubermenu .ubermenu-item {
  text-align: left;
  display: inline-block;
  vertical-align: top;
}

.ubermenu .ubermenu-item-level-0 {
  vertical-align: bottom;
  -moz-backface-visibility: visible;
  backface-visibility: visible;
}

.ubermenu-items-align-top .ubermenu-item-level-0 {
  vertical-align: top;
}

.ubermenu .ubermenu-item.ubermenu-item-level-0 {
  float: none;
}

.ubermenu-items-align-left.ubermenu-horizontal .ubermenu-item.ubermenu-item-level-0 {
  float: left;
}

.ubermenu.ubermenu-horizontal .ubermenu-item.ubermenu-align-left {
  float: left;
}

.ubermenu.ubermenu-horizontal .ubermenu-item.ubermenu-align-right {
  float: right;
}

body:not(.rtl) .ubermenu.ubermenu-horizontal .ubermenu-item-level-0:first-child > .ubermenu-target {
  border-left: none;
}

.ubermenu .ubermenu-colgroup .ubermenu-column, .ubermenu .ubermenu-custom-content-padded, .ubermenu .ubermenu-nonlink, .ubermenu .ubermenu-retractor, .ubermenu .ubermenu-submenu-padded, .ubermenu .ubermenu-submenu-type-stack > .ubermenu-item-normal > .ubermenu-target, .ubermenu .ubermenu-target, .ubermenu .ubermenu-widget {
  padding: 15px 15px;
}

.ubermenu-responsive-toggle {
  padding: 15px 15px;
}

.ubermenu .ubermenu-target-nowrap > .ubermenu-target-title {
  white-space: nowrap;
}

.ubermenu-horizontal .ubermenu-item.ubermenu-item-mini > .ubermenu-target {
  padding-left: 5px;
  padding-right: 5px;
}

.ubermenu .ubermenu-item-level-0.ubermenu-has-submenu-stack {
  position: relative;
}

.ubermenu .ubermenu-item-level-0 > .ubermenu-submenu-type-stack {
  padding-top: 0;
}

.ubermenu .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item {
  clear: both;
  width: 100%;
}

.ubermenu .ubermenu-submenu .ubermenu-submenu-type-stack {
  width: auto;
}

.ubermenu .ubermenu-autocolumn, .ubermenu .ubermenu-submenu-type-stack {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ubermenu .ubermenu-item-type-column > .ubermenu-submenu-type-stack {
  padding-top: 0;
}

.ubermenu .ubermenu-item-type-column > .ubermenu-submenu-type-stack > .ubermenu-item-normal:first-child {
  margin-top: 10px;
}

.ubermenu .ubermenu-submenu-type-stack > .ubermenu-item-normal > .ubermenu-target {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ubermenu .ubermenu-target {
  display: block;
  text-decoration: none;
  position: relative;
}

.ubermenu .ubermenu-target-with-image {
  overflow: hidden;
  display: flex !important;
  flex-flow: column !important;
  justify-content: center !important;
  align-content: center !important;
  text-align: center !important;
  align-items: center !important;
}

.ubermenu-sub-indicators .ubermenu-has-submenu-drop > .ubermenu-target {
  padding-right: 25px;
}

.ubermenu-sub-indicators .ubermenu-has-submenu-drop > .ubermenu-target:after {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -6px;
  content: "\f107";
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ubermenu-sub-indicators .ubermenu-item-has-children > .ubermenu-target.ubermenu-noindicator {
  padding-right: 20px;
}

.ubermenu-sub-indicators .ubermenu-item-mini.ubermenu-item-has-children > .ubermenu-target.ubermenu-noindicator {
  padding-right: 5px;
}

.ubermenu-sub-indicators .ubermenu-has-submenu-drop > .ubermenu-noindicator:after {
  content: '';
  display: none;
}

.ubermenu-sub-indicators .ubermenu-submenu-type-flyout > .ubermenu-has-submenu-drop > .ubermenu-target:after, .ubermenu-sub-indicators .ubermenu-submenu-type-stack > .ubermenu-has-submenu-drop.ubermenu-has-submenu-flyout > .ubermenu-target:after {
  content: "";
}

.ubermenu-sub-indicators .ubermenu-submenu .ubermenu-has-submenu-flyout.ubermenu-submenu-reverse > .ubermenu-target:after {
  right: auto;
  left: 10px;
  content: "";
}

.ubermenu .ubermenu-target-text {
  display: inline-block;
  vertical-align: middle;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
}

.ubermenu .ubermenu-target-description {
  font-size: 80%;
  font-weight: 400;
  clear: both;
  display: block;
}

.ubermenu .ubermenu-target-with-image > .ubermenu-target-text {
  display: block;
  clear: none;
  text-align: center;
}

.ubermenu .ubermenu-item.ubermenu-disable-padding > .ubermenu-target {
  padding: 0;
}

.ubermenu.ubermenu-sub-indicators .ubermenu-item.ubermenu-disable-padding.ubermenu-has-submenu-drop > .ubermenu-target {
  padding-right: 30px;
}

.ubermenu .ubermenu-image {
  display: block;
  max-width: 100%;
  transition: opacity 1s ease;
  opacity: 1;
}

.ubermenu .ubermenu-image:not(.ubermenu-image-lazyload) {
  height: auto;
}

.ubermenu .ubermenu-image-lazyload {
  opacity: 0;
}

.ubermenu .ubermenu-item-layout-image_left > .ubermenu-target-text {
  /*padding-left: 50px*/
}

.ubermenu .ubermenu-item-layout-image_left > .ubermenu-image {
  float: left;
}

.ubermenu .ubermenu-item-layout-image_right > .ubermenu-target-text {
  /*padding-right: 50px*/
}

.ubermenu .ubermenu-item-layout-image_right > .ubermenu-image {
  float: right;
}

.ubermenu .ubermenu-item-layout-image_above > .ubermenu-image {
  margin-bottom: 10px;
}

.ubermenu .ubermenu-item-layout-image_below > .ubermenu-image {
  margin-top: 10px;
}

.ubermenu .ubermenu-icon {
  width: 1.3em;
  text-align: center;
  line-height: 1em;
}

.ubermenu .ubermenu-item-notext > .ubermenu-icon {
  line-height: 1.3em;
}

.ubermenu .ubermenu-item-layout-icon_left > .ubermenu-icon, .ubermenu .ubermenu-item-layout-icon_right > .ubermenu-icon {
  vertical-align: middle;
}

.ubermenu .ubermenu-item-layout-icon_bottom > .ubermenu-icon, .ubermenu .ubermenu-item-layout-icon_top > .ubermenu-icon {
  line-height: 1.3em;
}

.ubermenu .ubermenu-item-layout-icon_left > .ubermenu-target-title {
  margin-left: .6em;
  display: inline-block;
}

.ubermenu.ubermenu-icons-inline .ubermenu-item-layout-icon_left > .ubermenu-icon, .ubermenu.ubermenu-icons-inline .ubermenu-item-layout-icon_left > .ubermenu-target-title {
  display: inline;
  white-space: normal;
}

.ubermenu .ubermenu-content-align-left {
  text-align: left;
}

.ubermenu .ubermenu-content-align-left > .ubermenu-image {
  display: inline-block;
}

.ubermenu .ubermenu-content-align-center {
  text-align: center;
}

.ubermenu .ubermenu-content-align-center > .ubermenu-image {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.ubermenu .ubermenu-content-align-right {
  text-align: right;
}

.ubermenu .ubermenu-content-align-right > .ubermenu-image {
  display: inline-block;
}

.ubermenu .ubermenu-column, .ubermenu .ubermenu-row {
  max-width: 100%;
}

.ubermenu .ubermenu-item .ubermenu-submenu-drop {
  position: absolute;
  z-index: 500;
  top: -10000px;
  height: 0;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
}

.ubermenu .ubermenu-item:not(.ubermenu-active) .ubermenu-submenu-drop {
  min-height: 0 !important;
}

.ubermenu .ubermenu-item .ubermenu-submenu-padded {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.ubermenu .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
  display: block;
  visibility: visible;
  opacity: 1;
  height: auto;
  max-height: none;
}

.ubermenu-nojs .ubermenu-item:hover > .ubermenu-submenu-drop {
  display: block;
  visibility: visible;
  opacity: 1;
  height: auto;
  max-height: none;
}

.ubermenu .ubermenu-item.ubermenu-active > .ubermenu-submenu-type-flyout, .ubermenu-nojs .ubermenu-item:hover > .ubermenu-submenu-type-flyout {
  overflow: visible;
}

.ubermenu .ubermenu-item.ubermenu-in-transition > .ubermenu-submenu-type-flyout {
  overflow: hidden;
}

.ubermenu .ubermenu-item .ubermenu-submenu-drop {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.ubermenu .ubermenu-item-level-0 > .ubermenu-submenu-drop {
  clip: rect(0, 5000px, 5000px, -5000px);
}

.ubermenu-bound .ubermenu-submenu-type-mega {
  min-width: 50%;
}

.ubermenu .ubermenu-grid-row, .ubermenu .ubermenu-submenu-grid {
  padding-right: 20px;
}

.ubermenu .ubermenu-grid-row .ubermenu-target, .ubermenu .ubermenu-submenu-grid .ubermenu-target {
  padding-right: 0;
}

.ubermenu .ubermenu-item.ubermenu-active > .ubermenu-target {
  position: relative;
}

.ubermenu-nojs .ubermenu-item:hover > .ubermenu-target {
  position: relative;
}

.ubermenu .ubermenu-item.ubermenu-active .ubermenu-submenu-drop, .ubermenu-nojs .ubermenu-item:hover .ubermenu-submenu-drop {
  z-index: 20;
}

.ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-left_edge_item {
  left: auto;
}

.ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-right_edge_item {
  right: auto;
}

.ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-full_width, .ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-left_edge_bar {
  left: 0;
}

.ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-right_edge_bar {
  right: 0;
}

.ubermenu .ubermenu-submenu-rtl {
  direction: rtl;
}

.ubermenu .ubermenu-submenu-rtl > * {
  direction: ltr;
}

.ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
  width: 100%;
}

.ubermenu .ubermenu-retractor {
  display: block;
  clear: both;
  text-align: center;
  cursor: pointer;
}

.ubermenu .ubermenu-retractor:hover {
  background: rgba(0, 0, 0, 0.1);
}

.ubermenu .ubermenu-retractor-desktop {
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px 10px;
  z-index: 100;
}

.ubermenu .ubermenu-submenu-type-stack > .ubermenu-item {
  display: block;
}

.ubermenu .ubermenu-submenu-type-stack > .ubermenu-item.ubermenu-column-auto {
  width: 100%;
  display: block;
  float: none;
  min-width: 0;
}

.ubermenu .ubermenu-item-header > .ubermenu-submenu-type-stack > .ubermenu-autocolumn {
  padding-top: 0;
  margin-top: 0;
}

.ubermenu .ubermenu-item-header > .ubermenu-submenu-type-stack > .ubermenu-autocolumn > .ubermenu-submenu-type-stack > .ubermenu-item-normal:first-child {
  padding-top: 0;
  margin-top: 0;
}

.ubermenu .ubermenu-autocolumn + .ubermenu-item-view-all {
  clear: both;
}

.ubermenu .ubermenu-submenu-type-stack.ubermenu-submenu-indent {
  padding-left: 1.6em;
  padding-top: 0.4em;
}

.ubermenu .ubermenu-submenu-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
  color: inherit;
  font-size: inherit;
  list-style: none;
}

.ubermenu .ubermenu-submenu-footer a {
  text-decoration: none;
  font-weight: 700;
  font-size: inherit;
}

.ubermenu .ubermenu-item.ubermenu-active.ubermenu-in-transition > .ubermenu-submenu-drop, .ubermenu .ubermenu-item.ubermenu-in-transition > .ubermenu-submenu-drop {
  overflow: hidden !important;
  height: auto;
}

.ubermenu .ubermenu-item .ubermenu-submenu-drop {
  -webkit-transition-duration: .3s;
  -ms-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-in;
  -ms-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.ubermenu-transition-none .ubermenu-item .ubermenu-submenu-drop {
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
  top: auto;
}

.ubermenu-nojs.ubermenu-transition-none .ubermenu-item:hover > .ubermenu-submenu-drop, .ubermenu-transition-none .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
  max-height: none;
  height: auto;
  overflow-y: visible;
}

.ubermenu-transition-slide .ubermenu-item .ubermenu-submenu-drop {
  -webkit-transition-property: max-height, visibility;
  -ms-transition-property: max-height, visibility;
  transition-property: max-height, visibility;
  max-height: 0;
  top: auto;
}

.ubermenu-nojs.ubermenu-transition-slide .ubermenu-item:hover > .ubermenu-submenu-drop, .ubermenu-transition-slide .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
  max-height: 1000px;
}

.ubermenu-transition-fade .ubermenu-item .ubermenu-submenu-drop {
  opacity: 0;
  -webkit-transition-property: opacity, visibility;
  -ms-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  top: auto;
  margin-top: 1px;
}

.ubermenu-nojs.ubermenu-transition-fade .ubermenu-item:hover > .ubermenu-submenu-drop, .ubermenu-transition-fade .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
  opacity: 1;
  top: auto;
}

.ubermenu-transition-shift .ubermenu-item .ubermenu-submenu-drop {
  margin-top: 20px;
  top: auto;
  opacity: 0;
  -webkit-transition-property: margin-top, opacity, visibility;
  -ms-transition-property: margin-top, opacity, visibility;
  transition-property: margin-top, opacity, visibility;
}

.ubermenu-nojs.ubermenu-transition-shift .ubermenu-item:hover > .ubermenu-submenu-drop, .ubermenu-transition-shift .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
  margin-top: 0;
  opacity: 1;
}

.ubermenu.ubermenu-transition-slide .ubermenu-active > .ubermenu-submenu.ubermenu-submenu-type-mega, .ubermenu:not(.ubermenu-transition-slide) .ubermenu-submenu.ubermenu-submenu-type-mega {
  max-height: 600px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ubermenu.ubermenu-disable-submenu-scroll .ubermenu-item > .ubermenu-submenu.ubermenu-submenu-type-mega {
  overflow: hidden;
}

.ubermenu.ubermenu-disable-submenu-scroll .ubermenu-active > .ubermenu-submenu.ubermenu-submenu-type-mega {
  max-height: none;
}

.ubermenu .ubermenu-submenu-type-flyout > .ubermenu-item {
  display: block;
  float: none;
  position: relative;
  min-width: 150px;
  clear: both;
}

.ubermenu:not(.ubermenu-transition-slide) .ubermenu-submenu.ubermenu-submenu-type-flyout {
  max-height: 99999px;
}

.ubermenu .ubermenu-submenu-type-flyout > .ubermenu-item.ubermenu-active {
  z-index: 50;
}

.ubermenu .ubermenu-submenu .ubermenu-submenu-type-flyout {
  margin-top: -1px;
  top: 0;
  left: 100%;
  min-width: none;
  clip: rect(-10px, 5000px, 5000px, 0);
}

.ubermenu .ubermenu-submenu .ubermenu-submenu-type-flyout.ubermenu-submenu-align-right_edge_item {
  left: auto;
  right: 100%;
  clip: rect(-10px, 5000px, 5000px, -5000px);
}

.ubermenu .ubermenu-submenu .ubermenu-item.ubermenu-active > .ubermenu-submenu-type-flyout {
  top: 0;
}

.ubermenu .ubermenu-submenu-type-flyout > .ubermenu-item.ubermenu-has-submenu-mega {
  position: static;
}

.ubermenu .ubermenu-submenu-type-flyout > .ubermenu-item > .ubermenu-submenu.ubermenu-submenu-type-mega {
  left: 100%;
  top: 0;
  min-height: 100%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
  clip: rect(-10px, 5000px, 5000px, -5000px);
}

.ubermenu.ubermenu-horizontal .ubermenu-item-level-1:first-child > .ubermenu-submenu-type-flyout {
  border-top: none;
}

.ubermenu.ubermenu-horizontal .ubermenu-item-level-1:first-child > .ubermenu-submenu-type-flyout > .ubermenu-item:first-child > .ubermenu-submenu-type-flyout {
  border-top: none;
}

.ubermenu.ubermenu-horizontal .ubermenu-submenu-type-flyout .ubermenu-submenu-type-mega {
  border-top: none;
}

.ubermenu .ubermenu-item .ubermenu-submenu-type-mega .ubermenu-submenu-drop {
  margin-top: 0;
  clip: rect(0, 5000px, 5000px, -5000px);
}

.ubermenu .ubermenu-item .ubermenu-submenu-type-mega .ubermenu-item.ubermenu-has-submenu-flyout {
  position: relative;
}

.ubermenu .ubermenu-item .ubermenu-submenu-type-mega > .ubermenu-item.ubermenu-has-submenu-flyout > .ubermenu-submenu-drop {
  top: auto;
  left: auto;
  box-shadow: none;
}

.ubermenu .ubermenu-submenu-type-mega .ubermenu-item.ubermenu-has-submenu-flyout.ubermenu-active {
  z-index: 100;
}

.ubermenu .ubermenu-row {
  margin-bottom: 10px;
  clear: both;
}

.ubermenu .ubermenu-row:after {
  content: "";
  display: table;
  clear: both;
}

.ubermenu .ubermenu-clear-row {
  clear: both;
}

.ubermenu .ubermenu-submenu .ubermenu-column {
  display: block;
  float: left;
  width: auto;
}

.ubermenu .ubermenu-submenu-type-mega.ubermenu-submenu-content-align-left {
  text-align: left;
}

.ubermenu .ubermenu-submenu-type-mega.ubermenu-submenu-content-align-right {
  text-align: right;
}

.ubermenu .ubermenu-submenu-type-mega.ubermenu-submenu-content-align-center {
  text-align: center;
}

.ubermenu .ubermenu-submenu-type-mega.ubermenu-submenu-content-align-center > .ubermenu-column, .ubermenu .ubermenu-submenu-type-mega.ubermenu-submenu-content-align-center > .ubermenu-row > .ubermenu-column {
  display: inline-block;
  float: none;
}

.ubermenu .ubermenu-submenu-type-mega.ubermenu-submenu-content-align-left > .ubermenu-column, .ubermenu .ubermenu-submenu-type-mega.ubermenu-submenu-content-align-left > .ubermenu-row > .ubermenu-column {
  display: inline-block;
  float: none;
}

.ubermenu .ubermenu-submenu-type-mega.ubermenu-submenu-content-align-right > .ubermenu-column, .ubermenu .ubermenu-submenu-type-mega.ubermenu-submenu-content-align-right > .ubermenu-row > .ubermenu-column {
  display: inline-block;
  float: none;
}

.ubermenu .ubermenu-submenu .ubermenu-column-auto {
  min-width: 100px;
  width: auto;
}

.ubermenu .ubermenu-nav .ubermenu-column-full {
  width: 100%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-2 {
  width: 50%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-3 {
  width: 33.33%;
}

.ubermenu .ubermenu-nav .ubermenu-column-2-3 {
  width: 66.66%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-4 {
  width: 25%;
}

.ubermenu .ubermenu-nav .ubermenu-column-3-4 {
  width: 75%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-5 {
  width: 20%;
}

.ubermenu .ubermenu-nav .ubermenu-column-2-5 {
  width: 40%;
}

.ubermenu .ubermenu-nav .ubermenu-column-3-5 {
  width: 60%;
}

.ubermenu .ubermenu-nav .ubermenu-column-4-5 {
  width: 80%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-6 {
  width: 16.66%;
}

.ubermenu .ubermenu-nav .ubermenu-column-5-6 {
  width: 83.33%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-7 {
  width: 14.28%;
}

.ubermenu .ubermenu-nav .ubermenu-column-2-7 {
  width: 28.57%;
}

.ubermenu .ubermenu-nav .ubermenu-column-3-7 {
  width: 42.85%;
}

.ubermenu .ubermenu-nav .ubermenu-column-4-7 {
  width: 57.14%;
}

.ubermenu .ubermenu-nav .ubermenu-column-5-7 {
  width: 71.42%;
}

.ubermenu .ubermenu-nav .ubermenu-column-6-7 {
  width: 85.71%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-8 {
  width: 12.5%;
}

.ubermenu .ubermenu-nav .ubermenu-column-3-8 {
  width: 37.5%;
}

.ubermenu .ubermenu-nav .ubermenu-column-5-8 {
  width: 62.5%;
}

.ubermenu .ubermenu-nav .ubermenu-column-7-8 {
  width: 87.5%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-9 {
  width: 11.11%;
}

.ubermenu .ubermenu-nav .ubermenu-column-2-9 {
  width: 22.22%;
}

.ubermenu .ubermenu-nav .ubermenu-column-4-9 {
  width: 44.44%;
}

.ubermenu .ubermenu-nav .ubermenu-column-5-9 {
  width: 55.55%;
}

.ubermenu .ubermenu-nav .ubermenu-column-7-9 {
  width: 77.77%;
}

.ubermenu .ubermenu-nav .ubermenu-column-8-9 {
  width: 88.88%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-10 {
  width: 10%;
}

.ubermenu .ubermenu-nav .ubermenu-column-3-10 {
  width: 30%;
}

.ubermenu .ubermenu-nav .ubermenu-column-7-10 {
  width: 70%;
}

.ubermenu .ubermenu-nav .ubermenu-column-9-10 {
  width: 90%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-11 {
  width: 9.09%;
}

.ubermenu .ubermenu-nav .ubermenu-column-2-11 {
  width: 18.18%;
}

.ubermenu .ubermenu-nav .ubermenu-column-3-11 {
  width: 27.27%;
}

.ubermenu .ubermenu-nav .ubermenu-column-4-11 {
  width: 36.36%;
}

.ubermenu .ubermenu-nav .ubermenu-column-5-11 {
  width: 45.45%;
}

.ubermenu .ubermenu-nav .ubermenu-column-6-11 {
  width: 54.54%;
}

.ubermenu .ubermenu-nav .ubermenu-column-7-11 {
  width: 63.63%;
}

.ubermenu .ubermenu-nav .ubermenu-column-8-11 {
  width: 72.72%;
}

.ubermenu .ubermenu-nav .ubermenu-column-9-11 {
  width: 81.81%;
}

.ubermenu .ubermenu-nav .ubermenu-column-10-11 {
  width: 90.9%;
}

.ubermenu .ubermenu-nav .ubermenu-column-1-12 {
  width: 8.33%;
}

.ubermenu .ubermenu-nav .ubermenu-column-5-12 {
  width: 41.66%;
}

.ubermenu .ubermenu-nav .ubermenu-column-7-12 {
  width: 58.33%;
}

.ubermenu .ubermenu-nav .ubermenu-column-11-12 {
  width: 91.66%;
}

.ubermenu .ubermenu-autoclear > .ubermenu-column-1-10:nth-child(10n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-1-11:nth-child(11n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-1-12:nth-child(12n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-1-2:nth-child(2n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-1-3:nth-child(3n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-1-4:nth-child(4n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-1-5:nth-child(5n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-1-6:nth-child(6n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-1-7:nth-child(7n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-1-8:nth-child(8n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-1-9:nth-child(9n+1), .ubermenu .ubermenu-autoclear > .ubermenu-column-full {
  clear: both;
}

.ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column {
  clear: none;
}

.ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-10:nth-child(10n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-11:nth-child(11n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-12:nth-child(12n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-2:nth-child(2n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-3:nth-child(3n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-4:nth-child(4n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-5:nth-child(5n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-6:nth-child(6n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-7:nth-child(7n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-8:nth-child(8n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-1-9:nth-child(9n+2), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top > .ubermenu-column-full {
  clear: both;
}

.ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top.ubermenu-submenu-retractor-top-2 > .ubermenu-column {
  clear: none;
}

.ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-10:nth-child(10n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-11:nth-child(11n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-12:nth-child(12n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-2:nth-child(2n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-3:nth-child(3n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-4:nth-child(4n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-5:nth-child(5n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-6:nth-child(6n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-7:nth-child(7n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-8:nth-child(8n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-1-9:nth-child(9n+3), .ubermenu .ubermenu-autoclear.ubermenu-submenu-retractor-top-2 > .ubermenu-column-full {
  clear: both;
}

.ubermenu .ubermenu-tabs {
  position: relative;
  width: 100%;
}

.ubermenu .ubermenu-tabs-group {
  border-style: solid;
}

.ubermenu .ubermenu-tab .ubermenu-tab-content-panel {
  position: absolute;
  display: none;
  border-width: 0;
  border-style: solid;
}

.ubermenu .ubermenu-tab > .ubermenu-target {
  border-width: 0;
  border-style: solid;
}

.ubermenu .ubermenu-tab.ubermenu-active > .ubermenu-tab-content-panel {
  display: block;
}

.ubermenu-nojs .ubermenu-tab:hover > .ubermenu-tab-content-panel {
  display: block;
}

.ubermenu-nojs .ubermenu-tabs-show-default > .ubermenu-tabs-group > .ubermenu-tab:first-child > .ubermenu-tab-content-panel {
  display: block;
}

.ubermenu-nojs .ubermenu-tabs-show-default:hover > .ubermenu-tabs-group > .ubermenu-tab:first-child > .ubermenu-tab-content-panel {
  display: none;
}

.ubermenu-nojs .ubermenu-tabs-show-default:hover > .ubermenu-tabs-group > .ubermenu-tab:first-child:hover > .ubermenu-tab-content-panel {
  display: block;
}

.ubermenu .ubermenu-tab-layout-left > .ubermenu-tabs-group {
  float: left;
  border-width: 0 1px 0 0;
  border-color: #eeeeee;
}

.ubermenu .ubermenu-tab-layout-left > .ubermenu-tabs-group > .ubermenu-tab > .ubermenu-tab-content-panel {
  right: 0;
  top: 0;
}

.ubermenu-sub-indicators .ubermenu-tabs.ubermenu-tab-layout-left > .ubermenu-tabs-group > .ubermenu-has-submenu-drop > .ubermenu-target:after {
  content: "";
}

.ubermenu .ubermenu-tab-layout-right > .ubermenu-tabs-group {
  float: right;
  border-width: 0 0 0 1px;
}

.ubermenu .ubermenu-tab-layout-right > .ubermenu-tabs-group > .ubermenu-tab > .ubermenu-tab-content-panel {
  left: 0;
  top: 0;
}

.ubermenu .ubermenu-tab-layout-right > .ubermenu-tabs-group > .ubermenu-has-submenu-drop > .ubermenu-target {
  padding-left: 25px;
  padding-right: 20px;
}

.ubermenu-sub-indicators .ubermenu-tab-layout-right > .ubermenu-tabs-group > .ubermenu-has-submenu-drop > .ubermenu-target:after {
  content: "\f104";
  right: auto;
  left: 10px;
}

.ubermenu .ubermenu-tab-layout-top > .ubermenu-tabs-group {
  border-width: 0;
}

.ubermenu .ubermenu-tab-layout-top > .ubermenu-tabs-group > .ubermenu-tab > .ubermenu-tab-content-panel {
  left: 0;
  top: auto;
  border-width: 1px 0 0;
}

.ubermenu .ubermenu-tab-layout-bottom > .ubermenu-tabs-group {
  border-width: 1px 0 0;
  position: absolute;
  bottom: 0;
}

.ubermenu .ubermenu-tab-layout-bottom > .ubermenu-tabs-group > .ubermenu-tab > .ubermenu-tab-content-panel {
  left: 0;
  bottom: 100%;
}

.ubermenu-sub-indicators .ubermenu-tabs.ubermenu-tab-layout-bottom > .ubermenu-tabs-group > .ubermenu-has-submenu-drop > .ubermenu-target:after {
  content: "";
}

.ubermenu h3.ubermenu-widgettitle {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 1.3em;
}

.widget.ubermenu_navigation_widget-class {
  overflow: visible;
}

.ubermenu .ubermenu-divider {
  clear: both;
  display: block;
  padding: 20px 0;
}

.ubermenu .ubermenu-submenu-type-stack > .ubermenu-divider {
  padding: 10px 0;
}

.ubermenu .ubermenu-divider hr {
  padding: 0;
  margin: 0;
  display: block;
  height: 1px;
  background: 0 0;
  border: 0;
  border-top: 1px solid #ddd;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.ubermenu .ubermenu-search .ubermenu-searchform {
  position: relative;
  background: #f9f9f9;
  width: 150px;
  margin: 7px;
}

.ubermenu .ubermenu-search input.ubermenu-search-input {
  width: 100%;
  padding: 8px 40px 8px 15px;
  color: #333;
  background: 0 0;
  border: none;
}

.ubermenu .ubermenu-search .ubermenu-search-submit {
  position: absolute;
  width: 40px;
  height: 100%;
  top: 0;
  right: 0;
  border: none;
  background: 0 0;
  cursor: pointer;
  color: #666;
  font-family: FontAwesome !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ubermenu .ubermenu-searchbar-drop .ubermenu-search-submit {
  position: absolute;
  width: 40px;
  height: 100%;
  top: 0;
  right: 0;
  border: none;
  background: 0 0;
  cursor: pointer;
  color: #666;
  font-family: FontAwesome !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ubermenu .ubermenu-submenu .ubermenu-search .ubermenu-searchform {
  height: 50px;
  margin: 0;
  background: #f9f9f9;
  width: 100%;
}

.ubermenu .ubermenu-submenu .ubermenu-search input.ubermenu-search-input {
  width: 100%;
  padding: 14px 50px 14px 25px;
  height: 100%;
  color: #333;
  background: 0 0;
}

.ubermenu .ubermenu-submenu .ubermenu-search .ubermenu-search-submit {
  position: absolute;
  width: 50px;
  height: 100%;
  top: 0;
  right: 0;
  border: none;
  background: 0 0;
  cursor: pointer;
  color: #666;
  font-family: FontAwesome !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ubermenu .ubermenu-submenu .ubermenu-searchbar-drop .ubermenu-search-submit {
  position: absolute;
  width: 50px;
  height: 100%;
  top: 0;
  right: 0;
  border: none;
  background: 0 0;
  cursor: pointer;
  color: #666;
  font-family: FontAwesome !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ubermenu .ubermenu-postlist-item {
  margin-bottom: 10px;
}

.ubermenu .ubermenu-postlist .ubermenu-image {
  float: left;
}

.ubermenu .ubermenu-postlist-title > a {
  color: inherit;
  text-decoration: none;
}

.ubermenu .ubermenu-postlist-content {
  font-size: 11px;
  opacity: 0.7;
}

.ubermenu .ubermenu-map-canvas img {
  max-width: none;
}

.ubermenu-screen-reader, .ubermenu-target-divider {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  padding: 0;
  margin: 0;
  border: 0;
}

.uber-clear:after {
  content: "";
  display: table;
  clear: both;
}

.ubermenu {
  background: 0 0;
}

.ubermenu .ubermenu-column:after, .ubermenu .ubermenu-content-block:after, .ubermenu .ubermenu-image:after, .ubermenu .ubermenu-postlist-item:after, .ubermenu .ubermenu-submenu:after, .ubermenu .ubermenu-tabs:after, .ubermenu:after {
  content: "";
  display: table;
  clear: both;
}

.ubermenu-submenu-drop {
  background: #fff;
}

.ubermenu-test-dimensions {
  visibility: hidden !important;
  display: block !important;
}

.ubermenu .ubermenu-force > .ubermenu-submenu {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  margin: 0 !important;
  top: auto !important;
  max-height: 600px;
  height: auto;
  overflow: auto !important;
}

.ubermenu-notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

.ubermenu-notransition > .ubermenu-submenu-drop {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

.ubermenu-admin-notice {
  background: #eee;
  color: #333;
  padding: 15px;
  border-left: 30px solid #4D90FE;
  margin: 20px;
  position: relative;
  font-size: 12px;
  clear: both;
}

.ubermenu-admin-notice a {
  color: #00A3FF;
  text-decoration: underline;
  font-size: inherit;
}

.ubermenu-admin-notice a:visited {
  color: #00A3FF;
  text-decoration: underline;
  font-size: inherit;
}

.ubermenu-admin-notice a:hover {
  color: #0D75AF;
}

.ubermenu-admin-notice strong {
  color: tomato;
}

.ubermenu-admin-notice .ubermenu-admin-notice-icon {
  position: absolute;
  left: -18px;
  top: 50%;
  margin-top: -5px;
  color: #fff;
  font-size: 13px;
}

.ubermenu-admin-notice:after {
  display: none;
  position: absolute;
  content: 'UberMenu Notice: Only displayed to admin users';
  left: -30px;
  bottom: 100%;
  background: #666;
  color: #eee;
  padding: 7px 10px;
  font-size: 11px;
}

.ubermenu-admin-notice:hover:after {
  display: block;
}

#wp-admin-bar-ubermenu .fa {
  font-family: FontAwesome;
  margin-right: 4px;
  color: #aaa;
}

#wp-admin-bar-ubermenu_loading .fa {
  font-family: FontAwesome;
  margin-right: 4px;
  color: #aaa;
  color: #ff0;
}

#wpadminbar #wp-admin-bar-ubermenu_loading #wp-admin-bar-ubermenu_loading_msg .ab-item {
  width: 300px;
  white-space: normal;
  height: auto;
  padding: 10px 20px;
  line-height: 1.5em;
}

.ubermenu-responsive-toggle.ubermenu-loc-ubermenu-direct-inject, .ubermenu.ubermenu-loc-ubermenu-direct-inject {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.admin-bar .ubermenu-responsive-toggle.ubermenu-loc-ubermenu-direct-inject, .admin-bar .ubermenu.ubermenu-loc-ubermenu-direct-inject {
  top: 32px;
}

span.ubermenu-target-title.ubermenu-target-text {
  font-size: 1.40em;
  text-transform: capitalize;
  font-weight: 100;
}

.ubermenu-submenu-type-auto span.ubermenu-target-title.ubermenu-target-text {
  font-size: 1em;
}

.ubermenu-item-layout-image_left span.ubermenu-target-title.ubermenu-target-text {
  display: block;
  width: 80%;
  margin: 0 auto;
  white-space: normal;
}

@media screen and (min-width: 959px) {
  /* ul#ubermenu-nav-main-15 > :nth-child(1),
  ul#ubermenu-nav-main-15 > :nth-child(2),
  ul#ubermenu-nav-main-15 > :nth-child(3){
    min-width: 15%;
  }
  ul#ubermenu-nav-main-15 > :nth-child(4){
    min-width: 10%;
  }
  ul#ubermenu-nav-main-15 > :nth-child(5){
    min-width: 10%;
  }
  ul#ubermenu-nav-main-15 > :nth-child(6),
  ul#ubermenu-nav-main-15 > :nth-child(7){
    max-width: 10%;
  }*/
  ul#ubermenu-nav-main-15 {
    max-width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 959px) {
  .ubermenu.ubermenu-loc-ubermenu-direct-inject {
    margin-top: 47px;
  }
  .ubermenu-nav {
    display: block;
  }
  .ubermenu-tab-content-panel {
    background: #fff !important;
    border: none !important;
  }
  .ubermenu .ubermenu-item-layout-image_left > .ubermenu-target-text {
    /* padding-left: 50px; */
    white-space: pre-wrap;
  }
  .ubermenu-nav > .ubermenu-item {
    white-space: normal;
  }
}

@media screen and (max-width: 782px) {
  .admin-bar .ubermenu-responsive-toggle.ubermenu-loc-ubermenu-direct-inject, .admin-bar .ubermenu.ubermenu-loc-ubermenu-direct-inject {
    top: 46px;
  }
  .ubermenu-responsive-toggle.ubermenu-loc-ubermenu-direct-inject, .ubermenu.ubermenu-loc-ubermenu-direct-inject {
    position: absolute;
  }
}

.ubermenu-vertical .ubermenu-item-level-0 {
  display: block;
  clear: both;
}

.ubermenu-vertical.ubermenu-items-align-right .ubermenu-item-level-0 {
  text-align: right;
}

.ubermenu-vertical.ubermenu-items-align-center .ubermenu-item-level-0 {
  text-align: center;
}

.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-submenu-drop {
  clip: rect(-5000px, 5000px, 5000px, 0);
}

.ubermenu.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-target {
  box-shadow: none;
  border-left: none;
}

.ubermenu-vertical.ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-item-has-children > .ubermenu-target:after {
  content: "";
}

.ubermenu-vertical .ubermenu-item > .ubermenu-submenu-drop {
  left: 100%;
  margin-left: 0;
  top: 0;
}

.ubermenu-nojs.ubermenu-vertical .ubermenu-item-level-0:hover > .ubermenu-submenu-drop {
  top: 0;
  min-height: 100%;
}

.ubermenu-vertical .ubermenu-active .ubermenu-submenu.ubermenu-submenu-type-flyout .ubermenu-submenu-type-mega, .ubermenu-vertical .ubermenu-item-level-0.ubermenu-active > .ubermenu-submenu-drop {
  top: 0;
  min-height: 100%;
}

.ubermenu-nojs.ubermenu-vertical .ubermenu-item-level-0:hover > .ubermenu-submenu-drop.ubermenu-submenu-align-vertical_parent_item {
  min-height: 0;
}

.ubermenu-vertical .ubermenu-item-level-0.ubermenu-active > .ubermenu-submenu-drop.ubermenu-submenu-align-vertical_parent_item {
  min-height: 0;
}

.ubermenu-vertical .ubermenu-item.ubermenu-flyout-full-height {
  position: static;
}

.ubermenu-vertical .ubermenu-item.ubermenu-flyout-full-height > .ubermenu-submenu-drop {
  min-height: 100% !important;
  max-height: none;
  height: auto;
}

.ubermenu-vertical .ubermenu-submenu .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop {
  top: 0;
}

.ubermenu-vertical.ubermenu-transition-slide .ubermenu-item.ubermenu-active.ubermenu-in-transition > .ubermenu-submenu-drop {
  min-height: 0;
}

.ubermenu-vertical .ubermenu-item > .ubermenu-submenu-type-mega {
  width: 600px;
  margin: 0;
}

.ubermenu-vertical .ubermenu-tab-layout-left > .ubermenu-tabs-group > .ubermenu-tab > .ubermenu-tab-content-panel, .ubermenu-vertical .ubermenu-tab-layout-right > .ubermenu-tabs-group > .ubermenu-tab > .ubermenu-tab-content-panel {
  min-height: 100%;
}

.ubermenu-vertical .ubermenu-item.ubermenu-relative {
  position: relative;
}

.ubermenu-vertical .ubermenu-item.ubermenu-relative.ubermenu-active {
  z-index: 20;
}

.ubermenu-vertical .ubermenu-item.ubermenu-item-level-0.ubermenu-relative.ubermenu-active > .ubermenu-submenu-drop.ubermenu-submenu-align-vertical_parent_item {
  top: 0;
}

.ubermenu-vertical .ubermenu-item.ubermenu-item-mini {
  float: left;
  clear: none;
}

.ubermenu.ubermenu-vertical .ubermenu-item.ubermenu-item-mini > .ubermenu-target {
  background: 0 0;
  box-shadow: none;
}

.rtl .ubermenu {
  text-align: right;
}

.rtl .ubermenu .ubermenu-item {
  text-align: right;
}

.rtl .ubermenu-items-align-left.ubermenu-horizontal {
  text-align: left;
}

.rtl .ubermenu-items-align-center.ubermenu-horizontal {
  text-align: center;
}

.rtl .ubermenu-items-align-left.ubermenu-horizontal .ubermenu-item.ubermenu-item-level-0 {
  float: none;
}

.rtl .ubermenu.ubermenu-horizontal .ubermenu-item-level-0:last-child > .ubermenu-target {
  border-left: none;
  box-shadow: none;
}

.rtl .ubermenu .ubermenu-submenu-type-mega > .ubermenu-column:not(.ubermenu-tabs-group), .rtl .ubermenu .ubermenu-submenu-type-tab-content-panel > .ubermenu-column:not(.ubermenu-tabs-group) {
  float: right;
}

.rtl .ubermenu .ubermenu-item-layout-icon_left > .ubermenu-target-title {
  margin-left: 0;
  margin-right: 0.6em;
}

.rtl .ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-has-submenu-drop > .ubermenu-target {
  padding-right: 20px;
  padding-left: 25px;
}

.rtl .ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-has-submenu-drop > .ubermenu-target:after {
  right: auto;
  left: 10px;
}

.rtl .ubermenu.ubermenu-sub-indicators .ubermenu-item.ubermenu-disable-padding.ubermenu-has-submenu-drop > .ubermenu-target {
  padding-left: 30px;
  padding-right: 0;
}

@media screen and (min-width: 960px) {
  .ubermenu-responsive-default.ubermenu {
    display: block !important;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-item.ubermenu-hide-desktop {
    display: none !important;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-retractors-responsive .ubermenu-retractor-mobile {
    display: none;
  }
  .ubermenu-responsive-default.ubermenu-force-current-submenu .ubermenu-item-level-0.ubermenu-current-menu-ancestor > .ubermenu-submenu-drop, .ubermenu-responsive-default.ubermenu-force-current-submenu .ubermenu-item-level-0.ubermenu-current-menu-item > .ubermenu-submenu-drop {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    margin: 0 !important;
    top: auto !important;
    height: auto;
    z-index: 19;
  }
  .ubermenu-responsive-default.ubermenu-invert.ubermenu-horizontal .ubermenu-item-level-0 > .ubermenu-submenu-drop {
    top: auto;
    bottom: 100%;
  }
  .ubermenu-responsive-default.ubermenu-invert.ubermenu-horizontal.ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-has-submenu-drop > .ubermenu-target:after {
    content: "";
  }
  .ubermenu-responsive-default.ubermenu-invert.ubermenu-horizontal .ubermenu-submenu .ubermenu-item.ubermenu-active > .ubermenu-submenu-type-flyout {
    top: auto;
    bottom: 0;
  }
  .ubermenu-responsive-default.ubermenu-invert.ubermenu-horizontal .ubermenu-item-level-0 > .ubermenu-submenu-drop {
    clip: rect(-5000px, 5000px, auto, -5000px);
  }
  .ubermenu-responsive-default.ubermenu-invert.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-submenu-drop {
    right: 100%;
    left: auto;
  }
  .ubermenu-responsive-default.ubermenu-invert.ubermenu-vertical.ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-item-has-children > a.ubermenu-target:after {
    right: auto;
    left: 10px;
    margin-top: -7px;
    content: "";
  }
  .ubermenu-responsive-default.ubermenu-vertical.ubermenu-invert .ubermenu-item > .ubermenu-submenu-drop {
    clip: rect(-5000px, 5000px, 5000px, -5000px);
  }
}

@media screen and (max-width: 959px) {
  .ubermenu-responsive-toggle, .ubermenu-sticky-toggle-wrapper {
    display: block;
  }
  .ubermenu-no-transitions.ubermenu-responsive.ubermenu-responsive-collapse {
    display: none;
    visibility: visible;
    height: auto;
    max-height: none;
    overflow: visible !important;
  }
  .ubermenu-sticky-wrapper {
    min-height: 0 !important;
  }
  .ubermenu-sticky-toggle-wrapper {
    display: block;
  }
  .ubermenu-responsive-default .ubermenu-responsive-toggle, .ubermenu-responsive-default .ubermenu-sticky-toggle-wrapper {
    display: block;
  }
  .ubermenu-responsive-default.ubermenu-responsive {
    width: 100%;
    max-height: 500px;
    visibility: visible;
    overflow: visible;
    -webkit-transition: max-height 1s ease-in;
    transition: max-height 0.3s ease-in;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-items-align-center {
    text-align: left;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu {
    margin: 0;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu .ubermenu-nav {
    display: block;
  }
  .ubermenu-responsive-default.ubermenu-repsonsive.ubermenu-no-transitions {
    display: block;
    max-height: none;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-responsive-nocollapse {
    display: block;
    max-height: none;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-responsive-collapse {
    max-height: 0;
    overflow: hidden !important;
    visibility: hidden;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-in-transition {
    overflow: hidden !important;
    visibility: visible;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-in-transition .ubermenu-nav {
    overflow: hidden !important;
    visibility: visible;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-responsive-collapse:not(.ubermenu-in-transition) {
    border-top-width: 0;
    border-bottom-width: 0;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-responsive-collapse .ubermenu-item .ubermenu-submenu {
    display: none;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-item-level-0 {
    width: 50%;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-responsive-single-column .ubermenu-item-level-0 {
    float: none;
    clear: both;
    width: 100%;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-item.ubermenu-item-level-0 > .ubermenu-target {
    border: none;
    box-shadow: none;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-item.ubermenu-has-submenu-flyout {
    position: static;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-sub-indicators .ubermenu-submenu-type-flyout .ubermenu-has-submenu-drop > .ubermenu-target:after {
    content: "";
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-nav .ubermenu-item .ubermenu-submenu.ubermenu-submenu-drop {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    top: auto;
    left: 0 !important;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-has-border .ubermenu-nav .ubermenu-item .ubermenu-submenu.ubermenu-submenu-drop {
    left: -1px !important;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-submenu.ubermenu-submenu-type-mega > .ubermenu-item.ubermenu-column {
    min-height: 0;
    border-left: none;
    float: left;
    display: block;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-item.ubermenu-active > .ubermenu-submenu.ubermenu-submenu-type-mega {
    max-height: none;
    height: auto;
    overflow: visible;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-transition-slide .ubermenu-item.ubermenu-in-transition > .ubermenu-submenu-drop {
    max-height: 1000px;
  }
  .ubermenu-responsive-default.ubermenu .ubermenu-submenu-type-flyout .ubermenu-submenu-type-mega {
    min-height: 0;
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-column, .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-column-auto {
    /*min-width: 50%*/
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-autoclear > .ubermenu-column {
    /*clear: none*/
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-column:nth-of-type(2n+1) {
    /*clear: both*/
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-submenu-retractor-top:not(.ubermenu-submenu-retractor-top-2) .ubermenu-column:nth-of-type(2n+1) {
    /*clear: none*/
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-submenu-retractor-top:not(.ubermenu-submenu-retractor-top-2) .ubermenu-column:nth-of-type(2n+2) {
    /*clear: both*/
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-tab, .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-tab-content-panel, .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-tabs, .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-tabs-group {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    left: 0;
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-tab-content-panel {
    min-height: 0 !important;
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-tabs {
    min-height: 0 !important;
    z-index: 15;
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-tab-content-panel {
    z-index: 20;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-tab {
    position: relative;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-tab.ubermenu-active {
    position: relative;
    z-index: 20;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-tab > .ubermenu-target {
    border-width: 0 0 1px;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-sub-indicators .ubermenu-tabs > .ubermenu-tabs-group > .ubermenu-tab.ubermenu-has-submenu-drop > .ubermenu-target:after {
    content: "";
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-tabs > .ubermenu-tabs-group > .ubermenu-tab > .ubermenu-tab-content-panel {
    top: auto;
    border-width: 1px;
  }
  .ubermenu-responsive-default.ubermenu-reponsive .ubermenu-item-level-0 > .ubermenu-submenu-type-stack {
    position: relative;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-submenu-type-stack .ubermenu-column, .ubermenu-responsive-default.ubermenu-responsive .ubermenu-submenu-type-stack .ubermenu-column-auto {
    width: 100%;
    max-width: 100%;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-item-mini {
    min-width: 0;
    width: auto;
    float: left;
    clear: none !important;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-item.ubermenu-item-mini > a.ubermenu-target {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-item.ubermenu-hide-mobile {
    display: none !important;
  }
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-hide-bkgs .ubermenu-submenu.ubermenu-submenu-bkg-img {
    background-image: none;
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-item-level-0.ubermenu-item-mini {
    min-width: 0;
    width: auto;
  }
  .ubermenu-responsive-default.ubermenu-vertical .ubermenu-item.ubermenu-item-level-0 {
    width: 100%;
  }
  .ubermenu-responsive-default.ubermenu-vertical.ubermenu-sub-indicators .ubermenu-item-level-0.ubermenu-item-has-children > .ubermenu-target:after {
    content: "";
  }
  .ubermenu-responsive-default.ubermenu-vertical .ubermenu-item.ubermenu-item-level-0.ubermenu-relative.ubermenu-active > .ubermenu-submenu-drop.ubermenu-submenu-align-vertical_parent_item {
    top: auto;
  }
}

@media screen and (max-width: 480px) {
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-item-level-0 {
    width: 100%;
  }
  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-column, .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-column-auto {
    min-width: 50%;
  }
}

li.ubermenu-item.ubermenu-item-has-children.ubermenu-column.ubermenu-column-auto.ubermenu-has-submenu-drop.parent-menu-item,
li.ubermenu-item.ubermenu-column.ubermenu-column-auto.parent-menu-item {
  border-top: 1px solid #cccccc;
}

li.ubermenu-item.ubermenu-item-has-children.ubermenu-column.ubermenu-column-auto.ubermenu-has-submenu-drop.parent-menu-item.ubermenu-current-menu-item,
li.ubermenu-item.ubermenu-column.ubermenu-column-auto.parent-menu-item.ubermenu-current-menu-item {
  border-color: #42b07a;
}
