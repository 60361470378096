.bg-green-site {
  background: #35af7b;
}
div#navbarSupportedContent {
  width: 100%;
  border-top: 2px solid #888888;
}

.nav-container {
  .border-top-gray {
    &.active {
      border-top: 2px solid #35af7b;
      margin-top: -2px;
    }
    &:hover {
      border-top: 2px solid #35af7b;
      margin-top: -2px;
    }
    a {
      color: #888888;
    }
  }

}

.border-bottom-gray {
  border-bottom: 1px solid #dbdbdb;
  &.active{
    a, span {
      color: #43b27b !important;
      font-weight: bold;
    }

  }
}
.border-top-bottom-gray {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

.border-right-gray {
  border-right: 2px solid #cccccc;
}
.home-box {
  max-width: 1400px;
  background-image: url(https://res.cloudinary.com/dbwozcf0d/image/upload/v1612418150/infantformula/IF_Pattern_2_1_wbbowr.png);
  background-size: 350px;
  background-repeat: no-repeat;
  background-position: calc(100% - -27px) 100%;
  position: relative;
}
.container-fluid.home-box > .container:before {
  content: ' ';
  background-image: url(https://res.cloudinary.com/dbwozcf0d/image/upload/v1612418658/infantformula/IF_Pattern_j4u8j4.png);
  background-repeat: no-repeat;
  width: 300px;
  height: 400px;
  display: block;
  position: absolute;
  background-size: contain;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.usp-section {
  .usp-item .content {
   max-width: 200px;
  }
}

.section-title {
  position: relative;
  -js-display: flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.section-title b {
  display: block;
  -ms-flex: 1;
  flex: 1;
  height: 2px;
  opacity: .1;
  background-color: currentColor;
}
.section-title-center span, .section-title-bold-center span {
  text-align: center;
}

button.navbar-toggler {
  background: #35af7b;
}
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.home-about-us {
  background: url("https://res.cloudinary.com/dbwozcf0d/image/upload/v1608178766/infantformula/homepage_u8dljm.png");
  background-repeat: no-repeat;
  background-position: center center ;
  background-size: cover;
  height: 100%;
  div[class*="col"] {
    background:$about-us-bg ;
  }
}


.navbar-toggler-icon  {
  background-image: url(https://mdbootstrap.com/img/svg/hamburger5.svg?);
  }

.home-masinory {
  /*.image-1 {
    background: url("/storage/app/media/theme/image-1.jpg");
  }
  .image-2 {
    background: url("/storage/app/media/theme/image-2.jpg");
  }
  .image-3 {
    background: url("/storage/app/media/theme/image-3.jpg");
  }
  .image-4 {
    background: url("/storage/app/media/theme/image-4.jpg");
  }
  .image-5 {
    background: url("/storage/app/media/theme/image-5.jpg");
  }*/
  div[class*="image"] {
    background-size: cover !important;
    background-position: center center !important; ;
  }
  .card-block {
    color: $white;
    z-index: 9;
    width: 100%;
  }
  .masionary-button {
    border-color: $white;
    color: $white;
  }
  .card.card-inverse {
    width: 100%;
    &:after {
      background-color: #0000005e;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 0;
    }
  }

}

.faq-section {
  .faq-item {
    p{
      max-width: 200px;
      margin: 0 auto;
    }
  }
}
.cust-button {
  border-radius: 20px;
  border: 1px solid #35af7b;
  margin: 0 auto;
  color: #35af7b;
  padding: 0.35em 1.5em;
}
.latest-review {
  &.item {

  }
  .h-100{
    background: #fff;
  }
}
.fa {
  color: $teal;
}
.latest-review.h-100 {
  background: #fff;
}


.owl-theme .owl-controls  {
  .owl-page{
    display: inline-block;
    span{
      background: none repeat scroll 0 0 $teal;
      border-radius: 20px;
      display: block;
      height: 12px;
      margin: 5px 7px;
      opacity: 0.5;
      width: 12px;
    }
  }
  .owl-pagination {
    text-align: center;
  }
}

.latest-articles-section {
  .latest-article{
    .content{

    }
  }
}

.top-rated-container {
  position: absolute;
  background: #FFF;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  text-align: left;
}

.table-container {
  display: table;
  width: 100%;
  height: 100%;
}


.footer{
  ul.fooer-list-menu {
    text-align: center;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 auto;
      padding: 0.25em 1em;
      a {
        color: $gray-100;
      }
    }
  }
  .footer-copyright {
    color: $gray-100;
    text-align: center;
    margin-bottom: 2em;
  }
}
.products-list {
  li {
    list-style: none;
  }
}

/*.gradiant-gray-1 {
  background: url(/storage/app/media/theme/gradiant-gray-1.png);
  background-repeat: repeat-y ;
  background-size: 100%;
  border-bottom-left-radius: 3%;
  border-bottom-right-radius: 3%;
  padding: 2em 0em;
}*/
.gradiant-gray-1 {
  background-image: url(/storage/app/media/theme/xo.png);
  background-repeat: repeat-y;
  border-bottom-left-radius: 3%;
  border-bottom-right-radius: 3%;
  padding: 2em 0em;
  background-color: #f7f7f7;
  background-size: 212px;
  background-position: calc(100% - -27px) 50%;
  background-repeat: no-repeat;
}

.container p {
  color: #888888;
}
.gradiant-gray-2 {
  /*background: #f5f5f5;*/
}
.single-product {
 /* .owl-carousel{
    display: none;
    width: 100%;
    text-align:center;
    .owl-item {
    float: none !important;
    position: relative;
    min-height: 1px;
    display:inline-block;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    }
  }
  .owl-wrapper {
    width: auto !important;
  }*/
  h1 {
    color: $green;
    font-weight: bold;
  }
  h2 {
    color: $green;
    font-weight: bold;
  }
  h3 {
    color: $green;
    font-weight: bold;
  }
}
.border-left-green {
  border-left: 3px solid $green !important;
}
.border-bottom-green {
  border-bottom: 3px solid $green !important;
}

.dropshadow {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
}

nav.navbar {
  padding-left: 0;
  padding-right: 0;
}
.contact-us-page {
  .contact-form {
    .form-control {
      padding: 1em;
      border: 1px solid #35af7b;
    }
  }
  .fa {
    font-size: 4rem;
    color: #efef00;
  }
}
.dropdown:hover>.dropdown-menu {
  display: block;
}
.dropdown>.dropdown-toggle:active {
  pointer-events: none;   // Add this, to prevent clicking dropdown's default click function
}

i.fa.fa-bars {
  color: #42af78;
}


.bottom-blog-panel .previous, .bottom-blog-panel .next {
  background: #fff;
  color: #333;
  position: relative;
}


.top-rated-image {
  height: 100px;
}

.user-comment-box{
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 25px;
  border-radius: 0px 30px 30px;
}

span.fa-layers.fa-fw, svg.iconify, .star-raiting {
  color: #35af7b;
}

.ss-result {
  margin-bottom: 2em;
}
.ss-result__aside {
  float: right;
  margin-left: .5em;
}
.ss-result__title {
  font-weight: bold;
  margin-bottom: .5em;
}
.ss-result__badge {
  font-size: .7em;
  padding: .2em .5em;
  border-radius: 4px;
  margin-left: .75em;
  background: #eee;
  display: inline-block;
}
.ss-result__text {
  margin-bottom: .5em;
}
.ss-result__url {
}
.ss-result__aside img {
  height: 133px;
}
.col-4.h-100 img {
  width: auto !important;
  max-height: 100px !important;
}
form.review-form.p-5 {
  border: 1px solid #42b07a;
}
.row-eq-height {
  display: inline-flex;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  box-sizing: border-box;
}


.post-list > div {
  margin-top: 3em !important;
  &:first-child{
    margin-top: 0em !important;
  }
}

h5.sidebar-heading {
  color: #a4a4a4;
  font-weight: 800;
}

.no-style{
  list-style: none !important;
}

h5.sidebar-heading:after {
  content: ' ';
  display: block;
  border-top: 3px solid #a4a4a4;
  width: 41px;
  margin-top: 6px;
}
section.sidebar ul :last-child {
  border: 0px;
}

img.blogs-image {
  max-width: 500px;
  }


.brand-logo-container {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  flex-flow: column;
}
label.advertisement-text {
  margin: 0em;
  font-size: 0.75em;
  font-weight: bold;
}