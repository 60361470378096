.pagination {
  display: flex;
  @include list-unstyled();
  @include border-radius();
  li{
    background: $white;
    margin: 0.25em;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $green;
    a{
      color: $green;
      padding: 0.9em 1em;
      line-height: 100%;
    }
    &.active{
      background: $green;
      a{
        color: $white;
      }
    }

  }
}
section.container-fluid.bg-articles {
  background: url(https://res.cloudinary.com/dbwozcf0d/image/upload/v1613616957/infantformula/Infant_Formula_FAQs_zf6cvm.png);
  text-align: center;
  height: 30em;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-flow: column;
  vertical-align: middle;
  justify-content: center;
  position: relative;
  .text-white{
    font-size: 3em;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  & > :after {
    content: " ";
    display: block;
    background: #11111175;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }
}
section.container-fluid.bg-faq {
  background: url(https://res.cloudinary.com/dbwozcf0d/image/upload/v1613617309/infantformula/Infant_Formula_Blogs_pne0x5.png);
  background-position: center center;
}
.page-link {
  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -$pagination-border-width;
  line-height: $pagination-line-height;
  color: $pagination-color;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  &:hover {
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: $pagination-focus-box-shadow;
  }

  // Opinionated: add "hand" cursor to non-disabled .page-link elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.page-item {
  &:first-child {
    .page-link {
      margin-left: 0;
      @include border-left-radius($border-radius);
    }
  }
  &:last-child {
    .page-link {
      @include border-right-radius($border-radius);
    }
  }

  &.active .page-link {
    z-index: 1;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}
